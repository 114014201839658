import React, {useState} from 'react'
import {Link} from 'react-router-dom'


export default function CommonNav() {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };
  return (
    <div className='bg-white shadow-md'>
      <div className='flex justify-between md:pr-10 pt-6 pb-6 pl-4 items-center'>
        <div className="text-2xl uppercase font-bold text-customtext" >SanMarry</div>
        <div className="hidden md:block">
            <ul className='flex gap-5 text-xl text-customtext1 '>
                <li><Link className='hover:text-customtext' to="/">Home</Link ></li>
                <li><Link className='hover:text-customtext' to="/signup">Signup</Link ></li>
                <li><Link className='hover:text-customtext' to="/login">Signin</Link ></li>
            </ul>
        </div>
        <div className="md:hidden pr-4">
          <button onClick={toggleMobileMenu} className="text-customtext1">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6">
              <path fillRule="evenodd" d="M3 6.75A.75.75 0 0 1 3.75 6h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 6.75ZM3 12a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 12Zm0 5.25a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75a.75.75 0 0 1-.75-.75Z" clipRule="evenodd" />
            </svg>
          </button>
        </div>
      </div>
      {isMobileMenuOpen && (
      <div className="md:hidden">
        <ul className='flex flex-col text-left pl-4 gap-4 text-xl pb-4 text-customtext1 '>
          <li><Link className='hover:text-customtext' to="/">Home</Link></li>
          <li><Link className='hover:text-customtext' to="/signup">Signup</Link></li>
          <li><Link className='hover:text-customtext' to="/login">Signin</Link></li>
        </ul>
      </div>
      )}
    </div>
  )
}
