import React from 'react'
import { useNavigate } from 'react-router-dom';
import CommonNav from './CommonNav';
import Footer from './Footer';


export default function Pay() {
    const navigate = useNavigate();

    function handleConfirm(){
      alert('"Registration Complete, and your profile is checked and verified by the admin shortly');
        navigate('/login')
    }
  return (
    <div>
      <CommonNav/>
      <div className="h-screen">
        <h1 className='text-center text-2xl uppercase mt-6'>Payment</h1>
        <div className="flex justify-center mt-2 px-4">

  <div className="pack1 bg-white flex w-full max-w-md flex-col gap-4 shadow-2xl p-6 rounded-lg">
    <p className='text-2xl text-center capitalize font-bold'>Select a Payment Option</p>
    <div className="flex m-auto text-left flex-col gap-3">
      <div className="flex gap-2">
        <form action="">
          <select className="w-full p-2 border rounded" name="" id="">
            <option className='capitalize' value="bank">Net Banking</option>
            <option className='capitalize' value="card">Card</option>
            <option className='uppercase' value="upi">UPI</option>
          </select>
        </form>
      </div>
    </div>
    
    <div className="flex justify-center pt-2">
      <button className='p-3 bg-indigo-500 rounded-xl uppercase text-white font-bold w-full' onClick={(e) => { e.preventDefault(); handleConfirm(); }}>Confirm</button>
    </div>
  </div>
</div>
      </div>
      <Footer/>
    </div>
  )
}
