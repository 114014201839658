import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { createContext, useState } from 'react';
import axios from 'axios';

import Home from './components/screens/common/Home';
import Signup from './components/screens/common/Signup';
import Pay from './components/screens/common/Pay';
import Signin from './components/screens/common/Signin';

import UserHome from './components/screens/user/UserHome';
import Profile from './components/screens/user/Profile';
import ProfileEdit from './components/screens/user/ProfileEdit';
import Userscard from './components/screens/user/Userscard';
import UserDetail from './components/screens/user/UserDetail';
import Plan from './components/screens/user/Plan';
import Payment from './components/screens/user/Payment';
import Message from './components/screens/user/Message';
import UserNots from './components/screens/user/UserNots';
import Changepass from './components/screens/user/Changepass';

import AdminHome from './components/screens/admin/AdminHome';
import Adminnots from './components/screens/admin/Adminnots';
import AdminNotDetail from './components/screens/admin/AdminNotDetail';
import AdminUsers from './components/screens/admin/AdminUsers';
import AdminPackages from './components/screens/admin/AdminPackages';

axios.defaults.baseURL = 'https://matrimony.infoxtechnologies.com'
export const UserContext = createContext()

function App() {
  const [uname,setUname] = useState("sandeep")
  return (
    <div className="App">
      <UserContext.Provider value={[uname,setUname]}>
        <Router>
          <Routes>
            {/* Common  */}

            <Route path='/' element={ <Home/> }></Route>
            <Route path='/signup' element={ <Signup/> }></Route>
            <Route path='/pay' element={ <Pay/> }></Route>
            <Route path='/login' element={ <Signin/> }></Route>
            
            {/* User */}
            <Route path='/user' element={ <UserHome/> }></Route>
            <Route path='/profile' element={ <Profile/> }></Route>
            <Route path='/changepass' element={ <Changepass/> }></Route>
            <Route path='/update/:id' element={ <ProfileEdit/> }></Route>
            <Route path='/users' element={ <Userscard/> }></Route>
            <Route path='/userdetail/:id' element={ <UserDetail/> }></Route>
            <Route path='/plan' element={ <Plan/> }></Route>
            <Route path='/payment/:id' element={ <Payment/> }></Route>
            <Route path='/userchats' element={ <Message/> }></Route>
            <Route path='/usernots' element={ <UserNots/> }></Route>

            {/* Admin */}
            <Route path='/admin' element={ <AdminHome/> }></Route>
            <Route path='/admnots' element={ <Adminnots/> }></Route>
            <Route path='/admuseres' element={ <AdminUsers/> }></Route>
            <Route path="/detail/:id" element={< AdminNotDetail/>}></Route>
            <Route path='/packages' element={ <AdminPackages/> }></Route>
          </Routes>
        </Router>
      </UserContext.Provider>
    </div>
  );
}

export default App;