import React,{lazy, Suspense,  useEffect,  useState} from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom';

import UserNav from './UserNav';
import Footer from '../common/Footer';

const LazyPackages = lazy(()=>import('../common/Packages'))
export default function ProfileEdit() {
    

    const token=localStorage.getItem('token');
    console.log("token",token)
    const [imgch1,setImgch1] = useState(false)
    const [imgch2,setImgch2] = useState(false)
    const [imgch3,setImgch3] = useState(false)

    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        gender: '',
        height: '',
        designation: '',
        place: '',
        district: '',
        mlevel: '',
        mnumber: '',
        relegion: '',
        cast: '',
        fname: '',
        fjob: '',
        mname: '',
        mjob: '',
        sname: '',
        smarried: '',
        age: '',
        img1: null,
        img2: null,
        img3: null,
    });


    // Get User Data 
    useEffect(() => {
        async function fetchData() {
            try {
                const response = await axios.get(`profile`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Token ${token}`,
                    }
                });
                if (response.status === 200) {
                    const data = response.data.data;
                    if (Object.keys(data).length > 0) {
                        const responseData = response.data.data;
                        console.log("responseData", responseData);
                        // Update formData with response data
                        setFormData(prevState => ({
                            ...prevState,
                            first_name: responseData.user.first_name,
                            last_name: responseData.user.last_name,
                            email: responseData.user.email,
                            gender: responseData.gender,
                            height: responseData.height,
                            designation: responseData.designation,
                            place: responseData.place,
                            district: responseData.district,
                            mlevel: responseData.mlevel,
                            mnumber: responseData.mnumber,
                            relegion: responseData.relegion,
                            cast: responseData.cast,
                            fname: responseData.fname,
                            fjob: responseData.fjob,
                            mname: responseData.mname,
                            mjob: responseData.mjob,
                            sname: responseData.sname,
                            smarried: responseData.smarried,
                            age: responseData.age,
                            img1: responseData.img1,
                            img2: responseData.img2,
                            img3: responseData.img3,
                        }));
                    } else {
                        alert('Data is empty');
                        console.warn('Data is empty');
                    }
                } else {
                    alert("Error fetching data");
                }
            } catch (error) {
                console.error(error);
            }
        }
        fetchData();
    }, [token]);


    useEffect(() => {
        console.log("Updated formData", formData);
        setRelegion(formData.relegion)
    }, [formData]);


    const religiondata = {
        hindu: ['Nambudiri', 'Nair', 'Kshatriya','Ezhava','Vishwaksrma','Vellala','Araya','Pulaya','Paraya','Kuruva','Cheruman'],
        christians: ['Syrian', 'Latin', 'protestans','Evangelical','Lutheran'],
        muslims: ['Mappila', 'Sayyid', 'Sheikh','Moplah','Thangal'],
    };
    
    
    // State for selected category and item
    const [relegion,setRelegion]=useState("")
    const [cast,setCast]=useState("")

    
    // Handler for category change
    const handleCategoryChange = (e) => {
        setRelegion(e.target.value);
        setCast(''); // Reset item when category changes
        console.log(options)
    };

    // Options for the second select based on selected category
    const options = relegion in religiondata ? religiondata[relegion] : [];
    
    

    
      
    //New 
    
    
    const handleChange = (e) => {
        const { name, value, type, files } = e.target;

        if (type === 'file') {
            setFormData({
                ...formData,
                [name]: files ? files[0] : null, // Handle file uploads
            });
        } else {
            setFormData({
                ...formData,
                [name]: value, // Handle text, select, and radio inputs
            });
        }
        console.log("handleChange formData",formData)
    };
    

    const navigate=useNavigate();

    //Error
    const [errors, setErrors] = useState({});

    const validate = () => {
        const newErrors = {};
        if (!formData.first_name) newErrors.first_name = '*First name is required.';
        if (!formData.last_name) newErrors.last_name = '*Last name is required.';
        if (!formData.email) {
            newErrors.email = '*Email is required.';
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = '*Email is invalid.';
        }
        if (!formData.gender) newErrors.gender = '*Gender is required.';
        if (!formData.height) newErrors.height = '*Height is required.';
        if (!formData.designation) newErrors.designation = '*Designation is required.';
        if (!formData.place) newErrors.place = '*Place is required.';
        if (!formData.district) newErrors.district = '*District is required.';
        if (!formData.mlevel) newErrors.mlevel = '*Marriage level is required.';
        if (!formData.mnumber) {
            newErrors.mnumber = '*Mobile number is required.';
        } else if (!/^\d{10}$/.test(formData.mnumber)) {
            newErrors.mnumber = '*Mobile number must be exactly 10 digits.';
        }
        if (!formData.relegion) newErrors.relegion = '*Religion is required.';
        if (!formData.cast) newErrors.cast = '*Caste is required.';
        if (!formData.fname) newErrors.fname = "*Father's name is required.";
        if (!formData.fjob) newErrors.fjob = "*Father's job is required.";
        if (!formData.mname) newErrors.mname = "*Mother's name is required.";
        if (!formData.mjob) newErrors.mjob = "*Mother's job is required.";
        if (!formData.sname) newErrors.sname = "*Sibling's name is required.";
        if (!formData.smarried) newErrors.smarried = "*Sibling's marital status is required.";
        if (!formData.age) newErrors.age = '*Age is required.';
        console.log("error arry: ",newErrors)
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    
    

    async function handleUpdate(){
        if(!imgch1){
            formData.img1 = ""
        }
        if(!imgch2){
            formData.img2 = ""
        }
        if(!imgch3){
            formData.img3 = ""
        }
        console.log("final data for update",formData)
        if (validate()) {
            const data = new FormData();
            Object.keys(formData).forEach((key) => {
                data.append(key, formData[key]);
            });
            
            console.log("data",data)
            try {
                const response = await axios.put(
                    `update`,
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization':`Token ${token}`,
                        },
                    }
                    
                );
                console.log(response);
                
                if (response.status === 200) {
                                    
                    alert("updated")
                    navigate('/profile');

                } else {
                    alert('Error');
                }
            } catch (error) {
                alert('Error during request:', error);
                
            }
        }
    }
    
    
    {if (!formData) 
        return <div>Loading...</div>; // Show loading until data is fetched
    }
    return (
        <div>
            <UserNav/>
            
            <div className="flex min-h-full flex-col justify-center px-6 py-6 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                    <h2 className="heading  text-center text-2xl font-bold leading-9 tracking-tight text-gray-900 uppercase">Update </h2>
                </div>

                <div className="mt-10 md:w-4/5 sm:mx-auto ">
                    <form  className="space-y-6" action="#" method="POST">
                        <div className='md:flex gap-10'>
                            <div className="w-full md:w-3/4 ">
                                <label htmlFor="first_name" className="block text-sm font-medium leading-6 text-left text-gray-900">First Name</label>
                                <div className="mt-2">
                                    <input id="first_name" name="first_name" type="text" value={formData.first_name} onChange={handleChange}  required className="p-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                                </div>
                                {errors.first_name && <span className='flex text-red-500 justify-start '>{errors.first_name}</span>}
                                
                                
                            </div>
                            <div className="md:w-3/4">
                                <label htmlFor="last_name" className="block text-sm font-medium leading-6 text-left text-gray-900">Last Name</label>
                                <div className="mt-2">
                                    <input value={formData.last_name} id="last_name" name="last_name" type="text" onChange={handleChange}  required className="p-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                                </div>
                                {errors.last_name && <span className='flex text-red-500 justify-start '>{errors.last_name}</span>}
                            </div>
                                
                        </div>

                        <div className='md:flex gap-10'>
                            <div className="md:w-3/4">
                                <label htmlFor="gender" className="block text-sm font-medium leading-6 text-left text-gray-900">Gender</label>
                                <div className="mt-2 flex  gap-10">
                                    <div className="flex gap-4">
                                    <input checked={formData.gender === 'male'} id="male" name="gender" value="male" type="radio" onChange={handleChange}  required className="block w-full  sm:text-sm sm:leading-6"/>
                                    <label htmlFor="male">Male</label>
                                    </div>
                                    <div className="flex gap-4">
                                    <input checked={formData.gender === 'female'} id="female" name="gender" value="female" type="radio" onChange={handleChange}  required className="block w-full  sm:text-sm sm:leading-6"/>
                                    <label htmlFor="female">Female</label>
                                    </div>
                                        
                                </div>
                                {errors.gender && <span className='flex text-red-500 justify-start '>{errors.gender}</span>}
                            </div>
                            <div className="md:w-3/4">
                                <label htmlFor="height" className="block text-sm font-medium leading-6 text-left text-gray-900">Height</label>
                                <div className="mt-2">
                                    <input value={formData.height} id="height" name="height" type="text" onChange={handleChange} required className="p-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                                </div>
                                {errors.height && <span className='flex text-red-500 justify-start '>{errors.height}</span>}
                            </div>
                        </div>

                        <div className='md:flex gap-10'>
                            <div className="md:w-3/4">
                                <label htmlFor="designation" className="block text-sm font-medium leading-6 text-left text-gray-900">Designation</label>
                                <div className="mt-2">
                                    <input value={formData.designation} id="designation" name="designation" type="text" onChange={handleChange}  required className="p-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                                </div>
                                {errors.designation && <span className='flex text-red-500 justify-start '>{errors.designation}</span>}
                            </div>
                            <div className="md:w-3/4">
                                <label htmlFor="place" className="block text-sm font-medium leading-6 text-left text-gray-900">Place</label>
                                <div className="mt-2">
                                    <input value={formData.place} id="place" name="place" type="text" onChange={handleChange} required className="p-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                                </div>
                                {errors.place && <span className='flex text-red-500 justify-start '>{errors.place}</span>}
                            </div>
                        </div>

                        <div className='md:flex gap-10'>
                            <div className="md:w-3/4">
                                <label htmlFor="district" className="block text-sm font-medium leading-6 text-left text-gray-900">District</label>
                                <div className="mt-2">
                                    <input value={formData.district} id="district" name="district" type="text" onChange={handleChange} required className="p-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300  focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                                </div>
                                {errors.district && <span className='flex text-red-500 justify-start '>{errors.district}</span>}
                            </div>
                            <div className="md:w-3/4">
                                <label htmlFor="mlevel" className="block text-sm font-medium leading-6 text-left text-gray-900">Marriage level</label>
                                <div className="mt-2  ">
                                    <select className='flex w-full p-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6' name="mlevel" id="mlevel" onChange={handleChange}>
                                        <option className='ml-auto' value="">Select a category</option>
                                        <option className='ml-auto' value="newmarriage">Newly Married</option>
                                        <option className='ml-auto' value="remarriage">Remarriage</option>
                                    </select>
                                </div>
                                {errors.mlevel && <span className='flex text-red-500 justify-start '>{errors.mlevel}</span>}
                            </div>
                        </div>

                        <div className='md:flex gap-10'>
                            <div className="md:w-3/4">
                                <label htmlFor="email" className="block text-sm font-medium leading-6 text-left text-gray-900">Email</label>
                                <div className="mt-2">
                                    <input value={formData.email} id="email" name="email" type="email" onChange={handleChange} autoComplete='email' required className="p-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                                </div>
                                {errors.email && <span className='flex text-red-500 justify-start '>{errors.email}</span>}
                            </div>
                            <div className="md:w-3/4">
                                <label htmlFor="mnumber" className="block text-sm font-medium leading-6 text-left text-gray-900">Mobile Number</label>
                                <div className="mt-2">
                                    <input value={formData.mnumber} id="mnumber" name="mnumber" type="text" onChange={handleChange} required className="p-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                                </div>
                                {errors.mnumber && <span className='flex text-red-500 justify-start '>{errors.mnumber}</span>}
                            </div>
                        </div>

                        <div className="md:flex gap-10">
                            <div className="md:w-3/4">
                                <label htmlFor="relegion" className="block text-sm font-medium leading-6 text-left text-gray-900">Relegion</label>
                                
                                <select name="relegion" value={formData.relegion} onChange={(event) => { handleCategoryChange(event); handleChange(event);}} className='p-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ing-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'>
                                    <option value="">Select a category</option>
                                    <option value="hindu">Hindu</option>
                                    <option value="christians">Christians</option>
                                    <option value="muslims">Muslims</option>
                                </select>
                                {errors.relegion && <span className='flex text-red-500 justify-start '>{errors.relegion}</span>}
                            </div>
                            <div className="md:w-3/4">
                                <label htmlFor="cast" className="block text-sm font-medium leading-6 text-left text-gray-900">Cast</label>
                                
                                <select value={formData.cast} onChange={handleChange} name='cast'   className='p-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ing-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'>
                                    <option value="">Select an item</option>
                                    {options.map((item) => (
                                        <option key={item} value={item}>
                                        {item}
                                        </option>
                                    ))}
                                </select>
                                {errors.cast && <span className='flex text-red-500 justify-start '>{errors.cast}</span>}
                            </div>
                        </div>


                        <div className="md:flex gap-10">
                            <div className="md:w-3/4">
                            <label htmlFor="fname" className="block text-sm font-medium leading-6 text-left text-gray-900">Father Name</label>
                                <div className="mt-2">
                                    <input value={formData.fname} id="fname" name="fname" type="text" onChange={handleChange} required className="p-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                                </div>
                                {errors.fname && <span className='flex text-red-500 justify-start '>{errors.fname}</span>}
                            </div>
                            <div className="md:w-3/4">
                                <label htmlFor="fjob" className="block text-sm font-medium leading-6 text-left text-gray-900">Job</label>
                                <div className="mt-2">
                                    <input value={formData.fjob} id="fjob" name="fjob" type="text" onChange={handleChange}  required className="p-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                                </div>
                                {errors.fjob && <span className='flex text-red-500 justify-start '>{errors.fjob}</span>}
                            </div>
                        </div>
                        
                        <div className="md:flex gap-10">
                            <div className=" md:w-3/4">
                            <label htmlFor="mname" className="block text-sm font-medium leading-6 text-left text-gray-900">Mother Name</label>
                                <div className="mt-2">
                                    <input value={formData.mname} id="mname" name="mname" type="text" onChange={handleChange} required className="p-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                                </div>
                                {errors.mname && <span className='flex text-red-500 justify-start '>{errors.mname}</span>}
                            </div>
                            <div className="md:w-3/4">
                                <label htmlFor="mjob" className="block text-sm font-medium leading-6 text-left text-gray-900">Job</label>
                                <div className="mt-2">
                                    <input value={formData.mjob} id="mjob" name="mjob" type="text" onChange={handleChange} required className="p-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                                </div>
                                {errors.mjob && <span className='flex text-red-500 justify-start '>{errors.mjob}</span>}
                            </div>
                        </div>

                        <div className="md:flex gap-10">
                            <div className="md:w-3/4">
                                <label htmlFor="sname" className="block text-sm font-medium leading-6 text-left text-gray-900">Sibling Name</label>
                                <div className="mt-2">
                                    <input value={formData.sname} id="sname" name="sname" type="text" onChange={handleChange} required className="p-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                                </div>
                                {errors.sname && <span className='flex text-red-500 justify-start '>{errors.sname}</span>}
                            </div>
                            <div className="flex justify-start flex-col md:w-3/4">
                                <label htmlFor="smarried" className="block text-sm font-medium leading-6 text-left text-gray-900">Married</label>
                                <div className="flex gap-10">
                                    <div className="mt-2 flex gap-4">
                                    <input checked={formData.smarried === 'yes'} id="smarriedy" name="smarried" type="radio" value="yes" onChange={handleChange} required className="block w-full  sm:text-sm sm:leading-6"/>
                                    <label htmlFor="smarriedy">Yes</label>
                                    </div>
                                    <div className="mt-2 flex gap-4">
                                        
                                        <input checked={formData.smarried === 'no'} id="smarriedn" name="smarried" type="radio" value="no" onChange={handleChange} required className="block w-full  sm:text-sm sm:leading-6"/>
                                        <label htmlFor="smarriedn">No</label>
                                    
                                    </div>
                                </div>
                                {errors.smarried && <span className='flex text-red-500 justify-start '>{errors.smarried}</span>}
                                
                            </div>
                        </div>
                        <div className="mt-6 md:flex gap-10">
                            <div className="md:w-1/2">
                                <label htmlFor="mnumber" className="block text-sm font-medium leading-6 text-left text-gray-900">Age</label>
                                <div className="mt-2">
                                    <input value={formData.age} id="age" name="age" type="text" onChange={handleChange} required className="p-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                                </div>
                                {errors.age && <span className='flex text-red-500 justify-start '>{errors.age}</span>}
                            </div>
                            
                            
                            
                        </div>
                        

                        <div className="md:flex justify-around">
                            <img src={formData.img1} alt="" width={200}/>
                            <img src={formData.img2} alt="" width={200}/>
                            <img src={formData.img3} alt="" width={200}/>
                        </div>
                        <div className="md:flex justify-around">
                        
                            <div className="">
                                <label htmlFor="sname" className="block text-sm font-medium leading-6 text-left text-gray-900">Image 1</label>
                                <div className="mt-2">
                                <input id="img1" name="img1" type="file" onChange={(e) => { handleChange(e); setImgch1(true);}} required className=" sm:text-sm sm:leading-6"/>
                                </div>
                                {errors.img1 && <span className='flex text-red-500 justify-start '>{errors.img1}</span>}
                            </div>
                            <div className="">
                                <label htmlFor="sname" className="block text-sm font-medium leading-6 text-left text-gray-900">Image 2</label>
                                <div className="mt-2">
                                <input id="img2" name="img2" type="file" onChange={(e) => { handleChange(e); setImgch2(true);}}  required className="block w-full  sm:text-sm sm:leading-6"/>
                                </div>
                                {errors.img2 && <span className='flex text-red-500 justify-start '>{errors.img2}</span>}
                            </div>
                            <div className="">
                                <label htmlFor="sname" className="block text-sm font-medium leading-6 text-left text-gray-900">Image 3</label>
                                <div className="mt-2">
                                <input id="img3" name="img3" type="file" onChange={(e) => { handleChange(e); setImgch3(true);}}   required className="block w-full  sm:text-sm sm:leading-6"/>
                                </div>
                                {errors.img3 && <span className='flex text-red-500 justify-start '>{errors.img3}</span>}
                            </div>
                            
                        </div>
                        
                        
                    </form>

                    
                </div>
                
                
                <div className='flex justify-center my-6'>
                    <button type="submit" onClick={handleUpdate} className="p-5 uppercase font-bold rounded-md bg-indigo-600 px-3 py-1.5 text-sm leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Update</button>
                </div>
                
            </div>
            <Footer/>
        </div>
    )
}
