import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


export default function Visitors() {

  const [visitor,setVisitor] = useState([])
  const [load,setLoad] = useState(false)
  const [have,setHave] = useState(false)

  const token=localStorage.getItem('token');
  const navigate = useNavigate()

  useEffect(()=>{
    async function fetchData(){
      try{
        const response=await axios.get(`visitors`,{
          headers:{
            'Content-Type':'application/json',
            'Authorization':`Token ${token}`,
          }
        })
        if(response.status===200){
          if (Object.keys(response.data).length > 0) {
            console.log(response.data.data);

            const uniqueVisitors = [];
            const seenNames = new Set();

            response.data.data.forEach(item => {
              const fullName = `${item.pro.first_name} ${item.pro.last_name}`;
              if (!seenNames.has(fullName)) {
                seenNames.add(fullName);
                uniqueVisitors.push(item);
              }
            });
            setVisitor(uniqueVisitors);
            setLoad(true)
            if(visitor.length > 0){
              setHave(true)
              
            }
            console.log('visitor',uniqueVisitors);
            
          } else {
              alert('Data is empty')
              console.warn('Data is empty');
          }
          
        }
        else{
          alert("error")
        }
      }
      catch(error){
        console.error(error)
      }
    }
    fetchData();
  },[token])

  function userDetails(id){
    navigate(`/userdetail/${id}`)
  }
  
  {if (!load) 
    return <div>Loading...</div>; // Show loading until data is fetched
  }
  return (
    <div className="visiters">
      <p className='heading text-xl uppercase font-semibold'>Visiters</p>
      <div className="flex flex-wrap gap-4">
        {visitor.length > 0 ? (
          visitor.map((item) => {
            return (
              <div
                className='flex gap-6 shadow-2xl p-6 rounded-lg mb-6'
                onClick={(e) => {
                  e.preventDefault();
                  userDetails(item.pro.id);
                }}
                key={item.pro.id} // Ensure each child has a unique key
              >
                <div className="flex text-left flex-col gap-3">
                  <p className='text-sm uppercase font-semibold'>
                    {item.pro.user.first_name} {item.pro.user.last_name}
                  </p>
                </div>
              </div>
            );
          })
        ) : (
          <p className="text-center text-gray-500">No Visitors</p>
        )}
      </div>
    
    </div>
  )

  
}
