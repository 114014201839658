import React,{ useState, useEffect } from 'react'
import axios from 'axios';
import UserNav from './UserNav';
import { useNavigate } from 'react-router-dom';
import Footer from '../common/Footer';


export default function Plan() {

    const token=localStorage.getItem('token');
    console.log(token)
    const [packages,setPackages] = useState([]);
    const [plan,setPlan] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    

    const month = 2;
    const processValidity = (validity) => {
      const result = validity / 30;
      if (Number.isInteger(result)) {
        if (result===1){
          return `${result} month`;
        }
        else{

          return `${result} months`; // Add month if result is an integer
        }
      } else {
        return `${validity} days`; // Append 'days' if result is not an integer
      }
    };

    const formatDate = (dateString) => {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(dateString).toLocaleDateString('en-US', options);
    };

    function handlePlan(id){
      navigate(`/payment/${id}`)
    }
    
    const handleClick = (e, item) => {
      e.preventDefault();
      console.log(item.pname);
      if (item.pname.toLowerCase() === 'free') {
        handlePlan(item.id+1);
      } else {
        handlePlan(item.id);
      }
    };
    
    // Get Current plan
    useEffect(()=>{
      async function fetchData(){
        try{
              const response=await axios.get(`buyedplan`,{
                headers:{
                  'Content-Type':'application/json',
                  'Authorization':`Token ${token}`,
                }
              })
              if(response.status===200){
                console.log(response.data.data);
                setPlan(response.data.data);
                console.log("sststs",plan);
              }
              else{
                alert("error")
              }
            }
            catch(error){
              console.error(error)
              alert("try error")
            }
    }
    fetchData();
    },[token])
    
    // Get all Plans
    useEffect(()=>{
        async function fetchData(){
            try{
                  const response=await axios.get(`/packages`,{
                    headers:{
                      'Content-Type':'application/json',
                    }
                  })
                  if(response.status===200){
                    
                    setPackages(response.data.data);
                    
                  }
                  else{
                    alert("error")
                    setError("Error fetching packages");
                  }
                }
                catch(error){
                  console.error(error)
                  setError("Error fetching packages");
                }
                finally {
                  setLoading(false);
              }
        }
        fetchData();
    },[])

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error loading data!</p>;
    return (
      
      <div className=''>
        <UserNav />
        <div className="md:flex mb-6">
          <div className="md:w-1/3 p-4">
            <h1 className='heading text-2xl font-bold text-center uppercase mb-6' >Plan Details</h1>
            {
            plan.map((item)=>{
              return(
              <div className="bg-white p-4 shadow-md rounded-lg">
                <h1 className='text-lg font-semibold text-center uppercase mt-6' >Current Plan</h1>
                <div className="flex justify-center">
                  <img src={require('../../images/gift.png')} width={200} className="   mb-2"/>
                </div>
                <div className="flex flex-col text-center gap-2 pt-2">

                    
                    <p className=''>Plan Name: <b className='capitalize'>{item.plan.pname}</b></p>
                    <p className=''>Validity: <b>{processValidity(item.plan.validity)}</b></p>
                   
                    <p className=''>Valid Till: <b>{formatDate(item.edate)}</b></p>
                    <p className='text-xl'>Price: <b>{item.plan.price}</b> &#x20B9;</p>
                    <button className='p-3 bg-custombutton rounded-xl uppercase text-white font-bold' onClick={(e) => handleClick(e, item.plan)}>Renew</button>

                </div>
              </div>
              )}
            )}
          </div>
          <div className="md:w-2/3 p-4">
            <h1 className='heading text-2xl font-bold text-center uppercase mb-6' >Avalable Plans</h1>
            <div className="bg-white p-4 shadow-md rounded-lg overflow-x-auto">
            <table class="min-w-full bg-white border border-gray-300">
              <thead>
                <tr class="w-full bg-gray-200 text-gray-700 uppercase text-sm leading-normal">
                  <th class="py-3 px-6 text-left">Plan Type</th>
                  <th class="py-3 px-6 text-left">Duration</th>
                  <th class="py-3 px-6 text-left">Cost</th>
                  <th class="py-3 px-6 text-left">Action</th>
                </tr>
              </thead>
              <tbody class="text-black text-sm font-light">
                {
                packages.map((item)=>{
                  if(item.pname.toLowerCase() !== 'free')
                  return(
                    <tr class="border-b border-gray-200 hover:bg-gray-100">
                      <td class="py-6 px-6 text-black capitalize">{item.pname}</td>
                      <td class="py-6 px-6 text-black capitalize">{processValidity(item.validity)}</td>
                      <td class="py-6 px-6 text-black capitalize">{item.price} &#x20B9;</td>
                      <td className=''><button className='p-3 bg-custombutton rounded-xl uppercase text-white font-bold' onClick={(e) => handleClick(e, item)}>select</button></td>
                    </tr>
                  )})
                }
              </tbody>
            </table>
            </div>
          </div>
        </div>
        
        
    
        <Footer/>
      </div>
    )
}
