import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import axios from 'axios';

import AdminNav from './AdminNav';
import Sidebar from './Sidebar';
import AdminUserPlan from './AdminUserPlan';
import Footer from '../common/Footer';

export default function AdminNotDetail() {

  const token=localStorage.getItem('token');
  const [userData, setUserData] = useState(null);
  
  const {id}=useParams();
  const navigate = useNavigate()
  console.log(id)
  useEffect(()=>{
    async function fetchData(){
        try{
              const response=await axios.get(`singleprofile/${id}`,{
                headers:{
                  'Content-Type':'application/json',
                  'Authorization':`Token ${token}`,
                }
              })
              if(response.status===200){
                console.log(response);
                setUserData(response.data.data);
              }
              else{
                alert("error")
              }
            }
            catch(error){
              console.error(error)
            }
    }
    fetchData();
  },[id,token])

  useEffect(() => {
    console.log("Updated userData:", userData);
  }, [userData]); 

  

  {if (!userData ) 
    return <div>Loading...</div>; // Show loading until data is fetched
  }

  return (
    <div>
      <AdminNav/>
      <div class="flex ">
        <Sidebar/>
        <div className="md:flex w-full h-100 pt-10 bg-white">
          <div className=" flex justify-center flex-col md:w-1/3 p-6">
              {userData.img1 && <img src={userData.img1} alt="img1" />}
              
              
          </div>
          <div className=" flex flex-col  md:w-2/3 p-6 gap-4 md:overflow-auto md:max-h-[80vh]">
              
              <h1 className='heading text-5xl capitalize'>{userData.user.first_name} {userData.user.last_name}</h1>
              <div className="flex gap-2">
                  <span className='bg-yellow-300 p-1'>{userData.designation}</span>
                  <span className='bg-yellow-300 p-1'>{userData.place}</span>
              </div>

              <h1 className='heading text-xl uppercase font-semibold'>Personal Information</h1>
              <div className="md:flex gap-4 w-full pt-3">
                  <div className="mb-4 flex flex-col justify-between md:w-1/2 gap-4">
                      <p className='flex items-center gap-2'>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
                          <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                      </svg>Name: {userData.user.first_name} {userData.user.last_name}</p>
                      <p className='flex items-center gap-2'>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
                          <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                      </svg>Age: {userData.age}</p>
                      <p className='flex items-center gap-2'>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
                          <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                      </svg>Gender: {userData.gender}</p>
                      <p className='flex items-center gap-2'>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
                          <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                      </svg>Height: {userData.height} Cm</p>
                      <p className='flex items-center gap-2'>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
                          <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                      </svg>Religion: {userData.relegion}</p>
                      <p className='flex items-center gap-2'>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
                          <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                      </svg>Caste: {userData.cast}</p>
                      <p className='flex items-center gap-2'>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
                          <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                      </svg>Profession: {userData.designation}</p>
                  </div>
                  <div className="mb-4 flex flex-col md:w-1/2 justify-between gap-4">
                      <p className='flex items-center gap-2'>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
                          <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                      </svg>Place: {userData.place}</p>
                      <p className='flex items-center gap-2'>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
                          <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                      </svg>Father Name: {userData.fname}</p>
                      <p className='flex items-center gap-2'>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
                          <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                      </svg>Father Job: {userData.fjob}</p>
                      <p className='flex items-center gap-2'>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
                          <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                      </svg>Mother Name: {userData.mname}</p>
                      <p className='flex items-center gap-2'>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
                          <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                      </svg>Mother Job: {userData.mjob}</p>
                      <p className='flex items-center gap-2'>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
                          <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                      </svg>Sibling Name: {userData.sname}</p>
                      <p className='flex items-center gap-2'>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
                          <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                      </svg>Sibling Married: {userData.smarried}</p>
              
                  </div>
                  
                  
              </div>
              <hr />

              <h1 className='heading text-xl uppercase font-semibold'>Photo gallery</h1>
              <div className="flex flex-wrap gap-6 items-center ">
                  {userData.img2 && <img className='object-cover h-48 w-30' src={userData.img2} alt="img2" style={{ width: '200px', height: '200px', margin: '5px' }} />}
                  {userData.img1 && <img className='object-cover h-48 w-30' src={userData.img1} alt="img1" />}
                  {userData.img3 && <img className='object-cover h-48 w-30' src={userData.img3} alt="img3" style={{ width: '200px', height: '200px', margin: '5px' }} />}
              </div>
              <hr />

              <h1 className='heading text-xl uppercase font-semibold'>Contact Info</h1>
              <div className="flex flex-col gap-4 pt-3">
                  <p>Phone: {userData.mnumber}</p>
                  <p>Email: {userData.user.email}</p>
                  <p>Place: {userData.district}</p>
              </div>
              

              

              
              
          <AdminUserPlan id={id}/>
          </div>
        </div>

      </div>
      <Footer/>
    </div>
  )
}
