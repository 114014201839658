import React, {useState} from 'react'
import { useNavigate,Link  } from 'react-router-dom';

export default function UserNav() {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };
  const navigate=useNavigate();
  const expired=localStorage.getItem('expired');
  async function logout(){
    localStorage.removeItem('token')
    navigate('/');
  }
  if(!expired){
  return (
    <div className='bg-white shadow-md'>
      <div className='flex justify-between pr-10 pt-6 pb-6 pl-4 items-center'>
        <div className="text-2xl uppercase font-bold text-customtext" >SanMarry</div>
        <div className="hidden md:block">
          <ul className='flex gap-5 text-xl text-customtext1'>
            <li><Link className='hover:text-customtext' to="/user">Home</Link></li>
            <li><Link className='hover:text-customtext' to="/profile">Profile</Link></li>
            <li><Link className='hover:text-customtext' to="/users">Users</Link></li>
            <li><Link className='hover:text-customtext' to="/plan">Package</Link></li>
            <li><Link className='hover:text-customtext' to="/userchats">Messages</Link></li>
            <li><Link className='hover:text-customtext flex' to="/usernots" >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-7">
                <path strokeLinecap="round" strokeLinejoin="round" d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0" />
              </svg>

              <div class="relative inline-block object-top">
                <span class="relative flex h-3 w-3">
                  <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
                  <span class="relative inline-flex rounded-full h-3 w-3 bg-sky-500"></span>
                </span>
              </div>
            </Link></li>
            <li><Link className='hover:cursor-pointer hover:text-customtext' onClick={(e)=>{e.preventDefault();logout();}}>Logout</Link></li>
          </ul>
        </div>
        <div className="md:hidden pr-4">
          <button onClick={toggleMobileMenu} className="text-customtext">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6">
              <path fillRule="evenodd" d="M3 6.75A.75.75 0 0 1 3.75 6h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 6.75ZM3 12a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 12Zm0 5.25a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75a.75.75 0 0 1-.75-.75Z" clipRule="evenodd" />
            </svg>
          </button>
        </div>
      </div>
      {isMobileMenuOpen && (
          <div className="md:hidden">
            <ul className='flex flex-col text-left pl-4 gap-2 text-xl pb-4 text-customtext1'>
              <li><Link className='hover:text-customtext' to="/user">Home</Link></li>
              <li><Link className='hover:text-customtext' to="/profile">Profile</Link></li>
              <li><Link className='hover:text-customtext' to="/users">Users</Link></li>
              <li><Link className='hover:text-customtext' to="/plan">Package</Link></li>
              <li><Link className='hover:text-customtext' to="/userchats">Messages</Link></li>
              <li><Link className='hover:text-customtext' to="/usernots" >Notification</Link></li>
              <li><a className='hover:cursor-pointer hover:text-customtext' onClick={(e)=>{e.preventDefault();logout();}}>Logout</a></li>
            </ul>
            
          </div>
        )}
    </div>
  )
  }
  else{
    return (
      <div className='bg-white shadow-md'>
        <div className='flex justify-between md:pr-6 pt-6 pb-6 pl-4 items-center'>
          <div className="text-2xl uppercase font-bold " style={{ color: '#ef9748' }}>SanMarry</div>
          <div className="hidden md:block">
              <ul className='flex gap-5 text-xl text-customtext'>
                <li><Link className='hover:text-customtext' to="/user">Home</Link></li>
                <li><Link className='hover:text-customtext' to="/profile">Profile</Link></li>
                <li><Link className='hover:text-customtext' to="/users">Users</Link></li>
                <li><Link className='hover:text-customtext' to="/plan">Package</Link></li>
                <li><Link className='hover:text-customtext' to="/userchats">Messages</Link></li>
                <li><Link className='hover:text-customtext' to="/usernots" >
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-7">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0" />
                  </svg>
                </Link></li>
                <li><Link className='hover:cursor-pointer hover:text-customtext' onClick={(e)=>{e.preventDefault();logout();}}>Logout</Link></li>
            </ul>
          </div>
          <div className="md:hidden pr-4">
            <button onClick={toggleMobileMenu} className="text-customtext">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6">
                <path fillRule="evenodd" d="M3 6.75A.75.75 0 0 1 3.75 6h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 6.75ZM3 12a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 12Zm0 5.25a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75a.75.75 0 0 1-.75-.75Z" clipRule="evenodd" />
              </svg>
            </button>
          </div>
        </div>
        {isMobileMenuOpen && (
          <div className="md:hidden">
            <ul className='flex flex-col text-left pl-4 gap-2 text-xl pb-4 text-customtext1'>
              <li><Link className='hover:text-customtext' to="/user">Home</Link></li>
              <li><Link className='hover:text-customtext' to="/profile">Profile</Link></li>
              <li><Link className='hover:text-customtext' to="/users">Users</Link></li>
              <li><Link className='hover:text-customtext' to="/plan">Package</Link></li>
              <li><Link className='hover:text-customtext' to="/userchats">Messages</Link></li>
              <li><Link className='hover:text-customtext' to="/usernots" >Notification</Link></li>
              <li><a className='hover:cursor-pointer' onClick={(e)=>{e.preventDefault();logout();}}>Logout</a></li>
            </ul>
            
          </div>
        )}
        
      </div>
    )
  }
}
