import React, { useState,useEffect } from 'react'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import UserNav from './UserNav'
import Footer from '../common/Footer';

export default function Userscard() {

    const navigate = useNavigate()
    const token=localStorage.getItem('token');
    console.log(token)
    const [profile,setprofile] = useState([])

    const [advs,setAdvs] = useState(false)

    const [search,setSearch] = useState('')
    const [search1,setSearch1] = useState('')

    const [mid,setMid] = useState('')
    const [sage,setSage] = useState('')
    const [eage,setEage] = useState('')
    const [caste,setCaste] = useState('')
    const [education,setEducation] = useState('')
    const [district,setDistrict] = useState('')
    const [mlevel,setMlevel] = useState('')
    const [job,setJob] = useState('')

    const [isVisible, setIsVisible] = useState(false);

    const toggleVisibility = () => {
        setIsVisible(!isVisible);
    };

    useEffect(()=>{
        async function fetchData(){
            try{
                  const response=await axios.get(`users`,{
                    headers:{
                      'Content-Type':'application/json',
                      'Authorization':`Token ${token}`,
                    }
                  })
                  if(response.status===200){
                    console.log(response);
                    setprofile(response.data.data);
                    console.log(response.data.data);
                  }
                  else{
                    alert("error")
                  }
                }
                catch(error){
                  console.error(error)
                }
        }
        fetchData();
    },[])

    function userDetails(id){
        navigate(`/userdetail/${id}`)
    }

    function userFilter(){
        setAdvs(true)
        console.log("caste",caste)
        setSearch('')
    }
    function resetFilter(){
        setAdvs(false)
        setMid('')
        setSage('')
        setEage('')
        setCaste('')
        setEducation('')
        setDistrict('')
        setMlevel('')
        setJob('')
    }
    return (
        <div>
            <UserNav/>
            <div className="flex md:hidden w-full justify-center p-2" style={{background: 'rgb(252, 232, 209)',}}>
                <button className='heading text-lg capitalize font-semibold flex items-center gap-2' onClick={toggleVisibility}>profile filter
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-4">
                        <path fillRule="evenodd" d="M3.792 2.938A49.069 49.069 0 0 1 12 2.25c2.797 0 5.54.236 8.209.688a1.857 1.857 0 0 1 1.541 1.836v1.044a3 3 0 0 1-.879 2.121l-6.182 6.182a1.5 1.5 0 0 0-.439 1.061v2.927a3 3 0 0 1-1.658 2.684l-1.757.878A.75.75 0 0 1 9.75 21v-5.818a1.5 1.5 0 0 0-.44-1.06L3.13 7.938a3 3 0 0 1-.879-2.121V4.774c0-.897.64-1.683 1.542-1.836Z" clipRule="evenodd" />
                    </svg>
                </button>
            </div>
            {isVisible && (
                <div className="w-full bg-white p-4 md:hidden  ">
                    <p className='heading text-xl uppercase font-bold'>Advance search</p>
                    <form action="" className='mt-4 flex flex-col gap-4 '>
                        <input className='w-full p-2 border' type="text" placeholder='Member ID' onChange={(e)=>setMid(e.target.value)}  value={mid}/>
                        <input className='w-full p-2 border' type="text" placeholder='Age start' onChange={(e)=>setSage(e.target.value)} value={sage}/>
                        <input className='w-full p-2 border' type="text" placeholder='Age end' onChange={(e)=>setEage(e.target.value)} value={eage}/>
                        <input className='w-full p-2 border' type="text" placeholder='Caste' onChange={(e)=>setCaste(e.target.value)} value={caste}/>
                        <input className='w-full p-2 border' type="text" placeholder='Education' onChange={(e)=>setEducation(e.target.value)} value={education}/>
                        <input className='w-full p-2 border' type="text" placeholder='District' onChange={(e)=>setDistrict(e.target.value)} value={district}/>
                        <input className='w-full p-2 border' type="text" placeholder='Job' onChange={(e)=>setJob(e.target.value)} />
                        <label htmlFor="">Marriage Level</label>
                        <select className='p-2 border' name="" id="" onChange={(e)=>setMlevel(e.target.value)}>
                            <option className='ml-auto' value="Newly Married">Newly Married</option>
                            <option className='ml-auto' value="remarriage">Remarriage</option>
                        </select>
                        <button type="submit" onClick={(e)=>{e.preventDefault();userFilter()}} class="p-5 uppercase font-bold rounded-md bg-custombutton px-3 py-1.5 text-sm leading-6 text-white shadow-sm hover:bg-custombutton1 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Filter</button>
                        
                    </form>
                </div>
            )}
            
            <p className='heading text-2xl uppercase font-bold mt-6 text-center'>Users</p>
            <div className="flex gap-2 flex-wrap justify-end pr-4 relative">
                <input className='sm:p-1 md:p-2 rounded-lg border-2 border-indigo-500' type="text" placeholder='Member ID Search' onChange={(e)=>{setSearch(e.target.value);setSearch1();resetFilter();}} value={search}/>
                <input className='sm:p-1 md:p-2 rounded-lg border-2 border-indigo-500' type="text" placeholder='Search' onChange={(e)=>{setSearch1(e.target.value);setSearch();resetFilter();}} value={search1}/>
        
            </div>

            
            
            <div className="p-4 flex mb-8">
                {/* Filter div */}
                <div className="w-1/3 bg-white p-4 hidden md:block ">
                    <p className='heading text-xl uppercase font-bold'>Advance search</p>
                    <form action="" className='mt-4 flex flex-col gap-4 '>
                        <input className='w-full p-2 border' type="text" placeholder='Member ID' onChange={(e)=>setMid(e.target.value)}  value={mid}/>
                        <input className='w-full p-2 border' type="text" placeholder='Age start' onChange={(e)=>setSage(e.target.value)} value={sage}/>
                        <input className='w-full p-2 border' type="text" placeholder='Age end' onChange={(e)=>setEage(e.target.value)} value={eage}/>
                        <input className='w-full p-2 border' type="text" placeholder='Caste' onChange={(e)=>setCaste(e.target.value)} value={caste}/>
                        <input className='w-full p-2 border' type="text" placeholder='Education' onChange={(e)=>setEducation(e.target.value)} value={education}/>
                        <input className='w-full p-2 border' type="text" placeholder='District' onChange={(e)=>setDistrict(e.target.value)} value={district}/>
                        <input className='w-full p-2 border' type="text" placeholder='Job' onChange={(e)=>setJob(e.target.value)} />
                        <label htmlFor="">Marriage Level</label>
                        <select className='p-2 border' name="" id="" onChange={(e)=>setMlevel(e.target.value)}>
                            <option className='ml-auto' value="newmarriage">Newly Married</option>
                            <option className='ml-auto' value="remarriage">Remarriage</option>
                        </select>
                        <button type="submit" onClick={(e)=>{e.preventDefault();userFilter()}} class="p-5 uppercase font-bold rounded-md bg-custombutton px-3 py-1.5 text-sm leading-6 text-white shadow-sm hover:bg-custombutton1 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Filter</button>
                        
                    </form>
                </div>
      
                {/* Cards container */}
                <div className="w-full md:w-2/3  pl-4">
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                        {/* Card example */}
                        {advs ? 
                            ( // Filter
                                profile.filter((item)=>{
                                    const isMidMatch = mid ? item.user.username.toLowerCase().includes(mid.toLowerCase()) : true;
                                    const isAgeMatch = (sage ? Number(item.age) >= Number(sage) : true) && (eage ? Number(item.age) <= Number(eage) : true);
                                    console.log("itemcaste",item.caste)
                                    const isCasteMatch = caste ? item.cast.toLowerCase().includes(caste.toLowerCase()) : true;
                                    const isEducationMatch = education ? item.education.toLowerCase().includes(education.toLowerCase()) : true;
                                    const isDistrictMatch = district ? item.district.toLowerCase().includes(district.toLowerCase()) : true;
                                    
                                    const isJobMatch = job ? item.designation.toLowerCase().includes(job.toLowerCase()) : true;
                                    const isLvlMatch = mlevel ? item.mlevel.toLowerCase().includes(mlevel.toLowerCase()) : true;
                                    console.log(isMidMatch)
                                    return isMidMatch && isAgeMatch && isCasteMatch && isEducationMatch && isDistrictMatch && isJobMatch && isLvlMatch;

                                }).map((item)=>{
                                    console.log(item)
                                    if (item.gender === 'male') {
                                        return (
                                            <div className="bg-white p-4 shadow-md rounded-lg" onClick={(e)=>{e.preventDefault();userDetails(item.id)}}>
                                                <img src={item.img1} alt={item.user.username} className="w-full h-40 object-contain mb-2"/>
                                                <div className="flex flex-col gap-2 pt-2">

                                                    <h3 className="font-semibold">{item.user.username}</h3>
                                                    <p className=''>{item.age} Yrs, {item.designation} </p>
                                                    <div className="flex gap-4 ">

                                                        <p className='text-lg'>{item.cast} </p>
                                                        <p className='text-lg'>Height: {item.height} </p>
                                                        <i className="fa fa-mars text-red-600 text-xl" aria-hidden="true"></i>
                                                    </div>
                                                </div>
                                            </div>
                                    
                                    )}
                                    if (item.gender === 'female') {
                                        return (
                                            <div className="bg-white p-4 shadow-md rounded-lg" onClick={(e)=>{e.preventDefault();userDetails(item.id)}}>
                                                <img src={item.img1} alt={item.user.username} className="w-full h-40 object-contain mb-2"/>
                                                <div className="flex flex-col gap-2 pt-2">
                                                    <h3 className="font-semibold">{item.user.username}</h3>
                                                    <p className=''>{item.age} Yrs, {item.designation} </p>
                                                    <div className="flex gap-4 ">

                                                        
                                                        <p className='text-lg'>{item.cast} </p>
                                                        <p className='text-lg'>Height: {item.height} </p>
                                                        <i className="fa fa-mars text-red-600 text-xl" aria-hidden="true"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                })
                            ):( // Search
                                profile.filter((item)=>{
                                    let searchLower = ''
                                    if(search) { 
                                        searchLower = search.toLowerCase();

                                        return searchLower === '' || 
                                        item.user.username.toLowerCase().includes(searchLower);
                                    }
                                    else if (search1){ 
                                        searchLower = search1.toLowerCase();

                                        return searchLower === '' || 
                                        item.user.username.toLowerCase().includes(searchLower) ||
                                        item.age.toString().includes(searchLower) || 
                                        item.mlevel.toString().includes(searchLower) || 
                                        item.cast.toLowerCase().includes(searchLower);
                                    }
                                    
                                    return searchLower === '' || 
                                        item.user.username.toLowerCase().includes(searchLower) ||
                                        item.age.toString().includes(searchLower) || 
                                        item.mlevel.toString().includes(searchLower) || 
                                        item.cast.toLowerCase().includes(searchLower);
                                        
                                }).map((item)=>{
                                    console.log(item)
                                    if (item.gender === 'male') {
                                        return (
                                            <div className="bg-white p-4 shadow-md rounded-lg" onClick={(e)=>{e.preventDefault();userDetails(item.id)}}>
                                            <img src={item.img1} alt={item.user.username} className="w-full h-40 object-contain mb-2"/>
                                            <div className="flex flex-col gap-2 pt-2">

                                                <h3 className="font-semibold">{item.user.username}</h3>
                                                <p className=''>{item.age} Yrs, {item.designation} </p>
                                                <div className="flex gap-4 ">

                                                    <p className='text-lg'>{item.cast} </p>
                                                    <p className='text-lg'>Height: {item.height} </p>
                                                    <i className="fa fa-mars text-red-600 text-xl" aria-hidden="true"></i>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    if (item.gender === 'female') {
                                        return (
                                            <div className="bg-white p-4 shadow-md rounded-lg" onClick={(e)=>{e.preventDefault();userDetails(item.id)}}>
                                            <img src={item.img1} alt={item.user.username} className="w-full h-40 object-contain mb-2"/>
                                            <div className="flex flex-col gap-2 pt-2">
                                                <h3 className="font-semibold">{item.user.username}</h3>
                                                <p className=''>{item.age} Yrs, {item.designation} </p>
                                                <div className="flex gap-4 ">

                                                    <p className='text-lg'>{item.cast} </p>
                                                    <p className='text-lg'>Height: {item.height} </p>
                                                    <i className="fa fa-mars text-red-600 text-xl" aria-hidden="true"></i>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    )}
                                })
                            )
                        }
                       
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}
