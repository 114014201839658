import React from 'react'

export default function About() {
  return (
    
    <div className="container mx-auto mb-10 py-12 md:py-24 ">
      <div className="md:flex gap-8 p-8 md:p-16 bg-white shadow-lg rounded-lg">
        <div className="flex-1">
          <img
            className="rounded w-full h-80 object-contain"
            src={require('../../images/img4.jpg')}
            alt="Couple"
          />
        </div>
        <div className="flex-1 p-4">
          <h2 className="heading text-2xl font-bold mb-4">Welcome to Our Matrimony Service</h2>
          <p className="text-gray-700">
            We believe in the power of love and connection. Our goal is to make the journey to finding your soulmate as seamless and enjoyable as possible. We understand that each person's story is unique, and we’re here to offer a supportive space where you can meet and connect with others who share your values and aspirations.
          </p>
        </div>
      </div>

      <div className="md:flex gap-8 p-8 md:p-16 bg-gray-100 shadow-lg rounded-lg mt-8">
        <div className="flex-1 p-4">
          <h2 className="heading text-2xl font-bold mb-4">Our Unique Approach</h2>
          <p className="text-gray-700">
            We offer a secure and effective platform for those serious about finding a life partner. Our comprehensive approach combines advanced matchmaking technology with personalized support to ensure a successful and satisfying search for your ideal match.
          </p>
        </div>
        <div className="flex-1">
          <img
            className="rounded w-full h-80 object-contain"
            src={require('../../images/img3.webp')}
            alt="Matchmaking"
          />
        </div>
      </div>
    </div>
  )
}
