import React from 'react'
import AdminNav from './AdminNav'
import Sidebar from './Sidebar'

import Footer from '../common/Footer'

export default function AdminHome() {
  const stats = {
    registeredUsers: 1500,
    activeUsers: 1200,
    premiumUsers: 300,
  };
  return (
    <div>
      <AdminNav/>
      <div class="flex h-screen">
        <Sidebar/> 
        <main class="flex-1 overflow-y-auto">
          {/* Main Content */}
          <main className="flex-1 p-6">
            <h2 className="text-2xl font-semibold mb-6">Dashboard Overview</h2>
            <div className="grid grid-cols-3 gap-4">
              {/* Stats Cards */}
              <div className="bg-white p-4 rounded-lg shadow">
                <h3 className="text-xl">Registered Users</h3>
                <p className="text-3xl font-bold">{stats.registeredUsers}</p>
              </div>
              <div className="bg-white p-4 rounded-lg shadow">
                <h3 className="text-xl">Active Users</h3>
                <p className="text-3xl font-bold">{stats.activeUsers}</p>
              </div>
              <div className="bg-white p-4 rounded-lg shadow">
                <h3 className="text-xl">Premium Users</h3>
                <p className="text-3xl font-bold">{stats.premiumUsers}</p>
              </div>
            </div>

            {/* Recent Registrations */}
            <div className="mt-6 bg-white p-4 rounded-lg shadow">
              <h3 className="text-xl">Recent User Registrations</h3>
              <ul>
                {/* Sample Data */}
                {['User 1', 'User 2', 'User 3'].map((user, index) => (
                  <li key={index} className="border-b py-2">{user}</li>
                ))}
              </ul>
            </div>
          </main>
        </main>
      </div>
      <Footer/>
    </div>
  )
}
