import React from 'react'

import CommonNav from './CommonNav'
import About from './About'
import Footer from './Footer'
import Heroreg from './Heroreg'


export default function Home() {
  return (
    <div>
      <CommonNav/>
      <Heroreg/>
      <About/>
      <Footer/>
    </div>
  )
}
