import React, { useState,useEffect,useContext } from 'react'
import AdminNav from './AdminNav'
import Sidebar from './Sidebar'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Footer from '../common/Footer';


export default function Adminnots() {

  const token=localStorage.getItem('token');
  const [notify,setNotify] = useState([])
  const [delete1,setDelete1] = useState()
  const [allow1,setAllow1] = useState()
  const navigate = useNavigate()

  
  
  useEffect(()=>{
      async function fetchData(){
          try{
                const response=await axios.get(`allowusers`,{
                  headers:{
                    'Content-Type':'application/json',
                    'Authorization':`Token ${token}`,
                  }
                })
                if(response.status===200){
                  console.log(response);
                  setNotify(response.data.data);
                  console.log(response.data.data);
                }
                else{
                  alert("error")
                }
              }
              catch(error){
                console.error(error)
              }
      }
      fetchData();
  },[token,delete1,allow1])

  
  async function Allowmember(id){
    try{
      const response=await axios.put(`Allowuser/${id}`,{
        headers:{
          'Content-Type':'application/json',
          'Authorization':`Token ${token}`,
        }
      })
      if(response.status===200){
        
        alert("User Allowed")
        setAllow1(1)

      }
      else{
        alert("error")
      }
    }
    catch(error){
      console.error(error)
      alert("try error")
    }
  }
  
  async function Removemember(id){
    try{
      const response=await axios.delete(`Removeuser/${id}`,{
        headers:{
          'Content-Type':'application/json',
          'Authorization':`Token ${token}`,
        }
      })
      if(response.status===200){
        
        alert("User Deleted")
        setDelete1(1)
      }
      else{
        alert("error")
      }
    }
    catch(error){
      console.error(error)
      alert("try error")
    }
  }
  return (
    <div>
      <AdminNav/>
      
      <div class="flex  ">
        <Sidebar/>
        <main className="flex-1 p-6 h-screen overflow-y-auto">

          <div className="flex justify-center p-4">
            {notify.length === 0 ? (
              <div className="max-w-sm mx-auto overflow-hidden">
                <div className="flex items-center justify-center p-2">
                  <img src={require('../../images/notification.png')} width={150} alt="No items available" />
                </div>
                <div className="p-6">
                  <p className="heading pt-4 font-bold uppercase text-xl text-center">No Notifications</p>
                </div>
              </div>
            ) : (
              <div className="flex flex-col gap-4 w-full max-w-2xl text-left rounded">
                {notify.map((item) => {
                  return (
                    <div key={item.id} className="p-4 md:p-6 pl-6 pr-6 shadow-lg rounded-lg flex justify-between bg-white">
                      <div className="name">
                        <p className='heading font-bold'>New User</p>
                        <p>{item.user.first_name} {item.user.last_name}</p>
                      </div>
                      <div className="flex gap-2 md:gap-4 items-center">
                        <button className='bg-green-500 p-2 rounded text-white transition ease-in-out hover:scale-105' onClick={(e) => { e.preventDefault(); Allowmember(item.id); }}>
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                          </svg>
                        </button>
                        <button className='bg-red-500 p-2 rounded text-white transition ease-in-out hover:scale-105' onClick={(e) => { e.preventDefault(); Removemember(item.id); }}>
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                          </svg>
                        </button>
                      </div>
                    </div>
                  )
                })}
              </div>
            )}
            
          </div>
        </main>

      </div>
      <Footer/>
      
    </div>
  )
}
