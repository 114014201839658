import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import axios from 'axios';

import UserNav from './UserNav'
import Footer from '../common/Footer';

export default function UserDetail() {

    const token=localStorage.getItem('token');
    const username=localStorage.getItem('username');


    const [userData, setUserData] = useState(null);
    const [userD, setUserD] = useState(false);
    const [blocked, setBlocked] = useState(false);

    const {id}=useParams();
    const navigate = useNavigate()
    console.log(id)

    // modal 
    const [isOpen, setIsOpen] = useState(false);
    const openModal = () => setIsOpen(true);
    const closeModal = () => setIsOpen(false);
    const [mess,setMess]=useState("")

    useEffect(()=>{
        async function fetchData(){
            try{
                const response=await axios.get(`singleprofile/${id}`,{
                    headers:{
                    'Content-Type':'application/json',
                    'Authorization':`Token ${token}`,
                    }
                })
                if(response.status===200){
                    if (Object.keys(response.data).length > 0) {
                        console.log(response.data.data);
                        setUserData(response.data.data);
                        console.log(userData);
                        if (response.data.data.blockedusers.includes(username)) {
                            setBlocked(true);
                        }
                        
                    } else {
                        alert('Data is empty')
                        console.warn('Data is empty');
                    }
                }
                else{
                    alert("error")
                }
                }
                catch(error){
                console.error(error)
                }
        }
        fetchData();
    },[id,token])

    useEffect(() => {
        console.log("Updated userData:", userData);
    }, [userData]); 
      
    async function handleMessage(){
        const data={
            mess:mess,
        }
        try{
              const response=await axios.post(`sendchat/${id}`,data,{
                headers:{
                  'Content-Type':'application/json',
                  'Authorization':`Token ${token}`,
                }
              })
              
              if(response.status===200){
                
                navigate(`/userdetail/${id}`);
                setIsOpen(false);
                alert("message send")
              }
              else{
                alert("error")
              }
            }
            catch(error){
              console.error(error)
              alert("try error")
            }
    }
    console.log("blocked",blocked)
    {if (!userData) 
        return <div>Loading...</div>; // Show loading until data is fetched
    }
    console.log("userData",userData)
    return (
        <div>
            <UserNav/>
            <div className="md:flex w-full bg-white">
                <div className=" flex justify-center flex-col md:w-1/3 p-6">
                    {userData.img1 && <img src={userData.img1} alt="img1" />}
                    
                    {!blocked && 
                    <button className='p-2 uppercase bg-blue-500 font-bold hover:bg-green-500 text-white' onClick={openModal}>Chat</button>
                   
                    }
                </div>
                <div className=" flex flex-col  md:w-2/3 p-6 gap-4 md:overflow-auto md:max-h-[80vh]">
                    
                    <h1 className='heading text-5xl capitalize'>{userData.user.first_name} {userData.user.last_name}</h1>
                    <div className="flex gap-2">
                        <span className='bg-yellow-300 p-1'>{userData.designation}</span>
                        <span className='bg-yellow-300 p-1'>{userData.place}</span>
                    </div>

                    <h1 className='heading text-xl uppercase font-semibold'>Personal Information</h1>
                    <div className="md:flex gap-4 w-full pt-3">
                        <div className="mb-4 flex flex-col justify-between md:w-1/2 gap-4">
                            <p className='flex items-center gap-2'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                            </svg>

                                Name: {userData.user.first_name} {userData.user.last_name}</p>
                            <p className='flex items-center gap-2'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                            </svg>Age: {userData.age}</p>
                            <p className='flex items-center gap-2'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                            </svg>Gender: {userData.gender}</p>
                            <p className='flex items-center gap-2'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                            </svg>Height: {userData.height} Cm</p>
                            <p className='flex items-center gap-2'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                            </svg>Religion: {userData.relegion}</p>
                            <p className='flex items-center gap-2'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                            </svg>Caste: {userData.cast}</p>
                            <p className='flex items-center gap-2'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                            </svg>Profession: {userData.designation}</p>
                        </div>
                        <div className="mb-4 flex flex-col md:w-1/2 justify-between gap-4">
                            <p className='flex items-center gap-2'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                            </svg>Place: {userData.place}</p>
                            <p className='flex items-center gap-2'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                            </svg>Father Name: {userData.fname}</p>
                            <p className='flex items-center gap-2'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                            </svg>Father Job: {userData.fjob}</p>
                            <p className='flex items-center gap-2'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                            </svg>Mother Name: {userData.mname}</p>
                            <p className='flex items-center gap-2'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                            </svg>Mother Job: {userData.mjob}</p>
                            <p className='flex items-center gap-2'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                            </svg>Sibling Name: {userData.sname}</p>
                            <p className='flex items-center gap-2'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                            </svg>Sibling Married: {userData.smarried}</p>
                    
                        </div>
                        
                        
                    </div>
                    <hr />

                    <h1 className='heading text-xl uppercase font-semibold'>Photo gallery</h1>
                    <div className="flex flex-wrap gap-6 items-center ">
                        {userData.img2 && <img className='object-cover h-48 w-30' src={userData.img2} alt="img2" style={{ width: '200px', height: '200px', margin: '5px' }} />}
                        {userData.img1 && <img className='object-cover h-48 w-30' src={userData.img1} alt="img1" />}
                        {userData.img3 && <img className='object-cover h-48 w-30' src={userData.img3} alt="img3" style={{ width: '200px', height: '200px', margin: '5px' }} />}
                    </div>
                    <hr />

                    <h1 className='heading text-xl uppercase font-semibold'>Contact Info</h1>
                    <div className="flex flex-col gap-4 pt-3">
                        <p>Phone: {userData.mnumber}</p>
                        <p>Email: {userData.user.email}</p>
                        <p>Place: {userData.district}</p>
                    </div>
                    

                    

                    
                    
                </div>

            </div>
            <Footer/>
            
            
            {isOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white rounded-lg p-6 w-1/3">
                    <h2 className="text-lg font-bold mb-4">Send Message</h2>
                    <textarea onChange={(e)=>setMess(e.target.value)} className='mb-4 w-full h-20 p-2 border-2' name="" id=""></textarea>
                    <div className="flex justify-end">
                        <button 
                        className="bg-blue-500 text-white px-4 py-2 rounded mr-4" 
                        onClick={(e)=>{e.preventDefault();handleMessage()}}
                        >
                        Send
                        </button>
                        <button 
                        className="bg-red-500 text-white px-4 py-2 rounded" 
                        onClick={closeModal}
                        >
                        Close
                        </button>
                    </div>
                    </div>
                </div>
            )}
        </div>
    )
}
