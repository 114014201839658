import React from 'react'
import { useState, useEffect } from 'react'
import axios from 'axios'


export default function Packages({onPlan}) {


    const [inputValue,setInputValue] = useState('');
    const [activeButton, setActiveButton] = useState(null);
    const [packages,setPackages] = useState([])
    
    useEffect(()=>{
      onPlan(inputValue)
    },[inputValue])

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const month = 2;
    const processValidity = (validity) => {
      const result = validity / 30;
      if (Number.isInteger(result)) {
        if (result===1){
          return `${result} month`;
        }
        else{

          return `${result} months`; // Add month if result is an integer
        }
      } else {
        return `${validity} days`; // Append 'days' if result is not an integer
      }
    };

    const handleButtonClick = (buttonId) => {
      setInputValue(buttonId)
      setActiveButton(buttonId);
    };

    useEffect(()=>{
        async function fetchData(){
            try{
                  const response=await axios.get(`packages`,{
                    headers:{
                      'Content-Type':'application/json',
                    }
                  })
                  if(response.status===200){
                    
                    setPackages(response.data.data);
                    
                  }
                  else{
                    alert("error")
                    setError("Error fetching packages");
                  }
                }
                catch(error){
                  console.error(error)
                  setError("Error fetching packages");
                }
                finally {
                  setLoading(false);
              }
        }
        fetchData();
    },[])

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error loading data!</p>;
  return (
    <div className='mt-6'>
      <h1 className='text-2xl font-bold text-center uppercase' > Plans</h1>
      <div className="md:flex sm:p-4 md:p-8 justify-around text-center gap-6">
        {
          packages.map((item)=>{
            return(
            <div key={item.id}  className="pack1 flex flex-1 flex-col gap-4 shadow-2xl p-5 md:p-10 ">
              <p className='text-2xl uppercase font-bold'>{item.pname}</p>
              <div className="flex m-auto text-left flex-col gap-3">
                <div className="flex gap-2">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6 text-green-600">
                    <path fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z" clipRule="evenodd" />
                  </svg>
                  <p className='text-xl'>{processValidity(item.validity)}</p>
                </div>
                <div className="flex gap-2">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6 text-green-600">
                    <path fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z" clipRule="evenodd" />
                  </svg>
                  <p className='text-xl'>30 Messages</p>
                </div>
                <div className="flex gap-2">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6 text-red-600">
                    <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-1.72 6.97a.75.75 0 1 0-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 1 0 1.06 1.06L12 13.06l1.72 1.72a.75.75 0 1 0 1.06-1.06L13.06 12l1.72-1.72a.75.75 0 1 0-1.06-1.06L12 10.94l-1.72-1.72Z" clipRule="evenodd" />
                  </svg>

                  <p className='text-xl'>Mail {item.id}</p>
                </div>
              </div>
              <p className='text-xl'>{item.price} &#x20B9;</p>
              <div className="flex justify-center pt-2">
                <button className='p-3 bg-indigo-500 rounded-xl uppercase text-white font-bold' onClick={(e)=>{e.preventDefault();handleButtonClick(item.id)}}  style={{...styles.button,  backgroundColor: activeButton === item.id ? '#4F46E5' : 'grey',  }}>select</button>
              </div>
            </div>
            )
          })
        }
        
        
      </div>
    </div>
  )
}
const styles = {
  
  button: {
    border: 'none',
    borderRadius: '10px',
    padding: '10px 15px',
    cursor: 'pointer',
  },
};