import React,{ useState, useEffect } from 'react'
import axios from 'axios';
import { useNavigate,Link  } from 'react-router-dom';

import UserNav from './UserNav'
import Footer from '../common/Footer';

export default function UserNots() {

  const token=localStorage.getItem('token');
  const expired=localStorage.getItem('expired');
  console.log(expired)
  const [plan,setPlan] = useState([]);
  const navigate = useNavigate();

  useEffect(()=>{
      async function fetchData(){
        try{
              const response=await axios.get(`buyedplan`,{
                headers:{
                  'Content-Type':'application/json',
                  'Authorization':`Token ${token}`,
                }
              })
              if (Object.keys(response.data).length > 0) {
                  console.log(response.data.data);
                  setPlan(response.data.data);
                  console.log("profile",plan);
                  
              } else {
                    alert('Data is empty')
                    console.warn('Data is empty');
              }
            }
            catch(error){
              console.error(error)
              alert("try error")
            }
    }
    fetchData();
  },[])

  function handleRenew(id){
    navigate(`/payment/${id}`)
  }

  if(expired === 'true'){
    return (
      <div>
        <UserNav/>
        <div className="h-screen">
          <h1 className='heading text-2xl m-4 uppercase text-center font-bold '>Notifications</h1>
          <div className="flex justify-center ">
            {
              plan.map((item)=>{
                  return(
                  <div className="flex w-2/3  bg-white flex-col text-left p-6 gap-1 rounded-lg shadow-xl">
                      <div className="flex justify-between mb-3">
                          <p>Your {item.plan.pname} plan has expired</p>
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                          </svg>
                      </div>
                      <div className="flex justify-start">
                        <button class="p-5 uppercase font-bold rounded-md bg-custombutton px-3 py-1.5 text-sm leading-6 text-white shadow-sm hover:bg-custombutton1" onClick={(e)=>{e.preventDefault();handleRenew(item.plan.id)}}>Renew</button> 
                      </div>
                      
                      
                  </div>
              )
              })
            }
          </div>

        </div>
        <Footer/>
      </div>
    )
  }
  else{
    return (
      <div>
        <UserNav/>
        <div class="max-w-sm mx-auto overflow-hidden h-screen">
        <h1 className='heading text-2xl m-4 uppercase text-center font-bold mt-8'>Notifications</h1>
          <div className="flex items-center justify-center p-2 ">
            <img className='' src={require('../../images/notification.png')} width={120} alt="No items available" />
          </div>
          <div class="">
            <p className="heading pt-4 font-bold uppercase text-xl text-center">No Notifications</p>
          </div>
        </div>
        <Footer/>
      </div>
    )
  }
}
