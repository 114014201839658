import React from 'react'
import { Link } from 'react-router-dom'

export default function Sidebar() {
  return (
    <nav class="w-48 bg-gray-800 text-gray-400  pt-4 pl-6 hidden md:block">
        <ul class="mt-4 text-left text-xl">
            <li className='mb-6'><Link className='hover:text-white font-bold' to="/admuseres">Home</Link></li>
            <li className='mb-6'><Link className='hover:text-white font-bold' to="/packages">Packages</Link></li>
            <li className='mb-6'><Link className='hover:text-white font-bold' to="/admnots">Notifications</Link></li>
            
        </ul>
    </nav>
  )
}
