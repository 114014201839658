import React, { useState,useEffect } from 'react'
import AdminNav from './AdminNav'
import Sidebar from './Sidebar'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Footer from '../common/Footer';


export default function AdminUsers() {
  const navigate = useNavigate()
  const token=localStorage.getItem('token');
  const [profile,setprofile] = useState([])
  const [notify,setNotify] = useState([])
  
  useEffect(()=>{
      async function fetchData(){
          try{
                const response=await axios.get(`users`,{
                  headers:{
                    'Content-Type':'application/json',
                    'Authorization':`Token ${token}`,
                  }
                })
                if(response.status===200){
                  console.log(response);
                  setprofile(response.data.data);
                  console.log(response.data.data);
                }
                else{
                  alert("error")
                }
              }
              catch(error){
                console.error(error)
              }
      }
      fetchData();
  },[token])

  useEffect(()=>{
    async function fetchData(){
        try{
              const response=await axios.get(`allowusers`,{
                headers:{
                  'Content-Type':'application/json',
                  'Authorization':`Token ${token}`,
                }
              })
              if(response.status===200){
                console.log(response);
                setNotify(response.data.data);
                console.log(response.data.data);
              }
              else{
                alert("error")
              }
            }
            catch(error){
              console.error(error)
            }
    }
    fetchData();
  },[token])

  

  function handleDetail(id){
    console.log(id)
    navigate(`/detail/${id}`)
  }

  async function Deletemember(id){
    try{
      const response=await axios.delete(`Deleteuser/${id}`,{
        headers:{
          'Content-Type':'application/json',
          'Authorization':`Token ${token}`,
        }
      })
      if(response.status===200){
        
        alert("User Deleted")
        
        navigate('/admuseres')
        

      }
      else{
        alert("error")
      }
    }
    catch(error){
      console.error(error)
      alert("try error")
    }
  }
  
  
  return (
    <div>
      <AdminNav/>
      <div class="flex ">
        <Sidebar/> 

        <main class="flex-1 p-6 h-screen overflow-y-auto">{/* Main Content */}
          <main className="flex-1 p-6">
            <h2 className="text-2xl font-semibold mb-6">Dashboard Overview</h2>
            <div className="grid sm:grid-cols-1 md:grid-cols-2 gap-4">
              {/* Stats Cards */}
              <div className="bg-white p-4 rounded-lg shadow">
                <h3 className="text-xl mb-2">Registered Users</h3>
                <p className="text-3xl font-bold">{profile.length}</p>
              </div>
              <div className="bg-white p-4 rounded-lg shadow">
                <h3 className="text-xl mb-2">Need Access</h3>
                <p className="text-3xl font-bold">{notify.length}</p>
              </div>
              
            </div>

            
          </main>
            <div className=" justify-center p-4">
              <h2 class="heading text-xl  text-left rounded text-white p-2 pl-4 w-full bg-black uppercase font-bold">All Users</h2>
              {profile.length === 0 ? (
                <div class="max-w-sm mx-auto ">
                  <div className="flex items-center justify-center p-2">
                    <img className='' src={require('../../images/user.png')} width={150} alt="No items available" />
                  </div>
                  <div class="p-6">
                    <p className="pt-4 font-bold uppercase text-xl text-center">No Users</p>
                  </div>
                </div>
                ) : (
                <div className=" shadow-lg rounded-lg pl-6 pr-6 pb-6 overflow-x-auto bg-white">
                
                  <table className='table-auto w-full border-collapse '>
                    <thead className=''>
                        <tr className='font-bold uppercase '>
                          <td className='p-4'>First Name</td>
                          <td className='p-4'>Last Name</td>
                          <td className='p-4'>Email</td>
                          <td className='p-4'>Contact Number</td>
                          <td className='p-4 text-center' colSpan={2}>Action</td>
                        </tr>
                    </thead>
                    <tbody className=''>
                    {
                      profile.map((item)=>{
                        return(
                          <tr className=''>
                            <td className='p-2 pl-4' key={item.id}>{item.user.first_name}</td>
                            <td className='p-2 pl-4' key={item.id}>{item.user.last_name}</td>
                            <td className='p-2 pl-4' key={item.id}>{item.user.email}</td>
                            <td className='p-2 pl-4' key={item.id}>{item.mnumber}</td>
                            <td className='fex p-2 pl-4 justify-center '>
                              
                              <svg onClick={(e)=>{e.preventDefault();Deletemember(item.id)}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6 text-red-400 hover:text-red-600">
                                <path fillRule="evenodd" d="M16.5 4.478v.227a48.816 48.816 0 0 1 3.878.512.75.75 0 1 1-.256 1.478l-.209-.035-1.005 13.07a3 3 0 0 1-2.991 2.77H8.084a3 3 0 0 1-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 0 1-.256-1.478A48.567 48.567 0 0 1 7.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 0 1 3.369 0c1.603.051 2.815 1.387 2.815 2.951Zm-6.136-1.452a51.196 51.196 0 0 1 3.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 0 0-6 0v-.113c0-.794.609-1.428 1.364-1.452Zm-.355 5.945a.75.75 0 1 0-1.5.058l.347 9a.75.75 0 1 0 1.499-.058l-.346-9Zm5.48.058a.75.75 0 1 0-1.498-.058l-.347 9a.75.75 0 0 0 1.5.058l.345-9Z" clipRule="evenodd" />
                              </svg>

                            </td>
                            <td className='fex p-2 pl-4 justify-center '>
                            <svg onClick={(e)=>{e.preventDefault();handleDetail(item.id)}} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 text-blue-500 hover:text-blue-600">
                              <path strokeLinecap="round" strokeLinejoin="round" d="m12.75 15 3-3m0 0-3-3m3 3h-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                            </svg>


                            </td>
                          </tr>
                        )
                      })
                    } 
                    </tbody>
                      
                  </table>
                  
                </div>
               
              )}
              
            </div>
        </main>
      </div>
      <Footer/>
    </div>
  )
}
