import React from 'react';
import {  useParams, useNavigate } from 'react-router-dom';

import axios from 'axios';

import UserNav from './UserNav'
import Footer from '../common/Footer';

export default function Payment() {

  const token=localStorage.getItem('token');
  console.log("token: ",token)
  const navigate = useNavigate();
  const {id}=useParams();
  var pid = id
  if(id === 5){
    pid = parseInt(id) + 2
  }
  console.log(pid)
  


  async function handleConfirm(){
    try{
        const response=await axios.post(`planpurchase/${pid}`,{},{
            headers:{
                'Content-Type':'application/json',
                'Authorization':`Token ${token}`,
            }
        });
        if(response.status===200){
          console.log("success")
          alert("purchased")
          let can1 = localStorage.getItem('expired')
          console.log('can1',can1)
          localStorage.setItem('expired','false')
          let can = localStorage.getItem('expired')
          console.log('can',can)
          navigate('/plan')
        }
        else{
          console.log("error")
        }
    }
    catch(error){
      console.error(error)
    }        
 }

  
  return (
    <div>
        <UserNav/>  
        <div className="h-screen">
          <h1 className='heading text-2xl capitalize font-bold mt-4 text-center'>Payment</h1>
          <div className="flex justify-center">

            <div  className="pack1  flex md:w-1/2 m-6 flex-col gap-4 shadow-2xl  p-10  rounded-lg bg-white">
              <p className='heading text-2xl capitalize font-bold text-center'>select a payment option</p>
              <div className="flex m-auto text-left flex-col gap-3">
                <div className="flex gap-2">
                  <form action="">
                    <select name="" id="">
                      <option className='capitalize' value="bank">Net Banking</option>
                      <option className='capitalize' value="card">Card</option>
                      <option className='uppercase' value="upi">UPI</option>
                    </select>
                  </form>
                
                </div>
                  
              </div>
              
              <div className="flex justify-center pt-2">
                  <button className='p-3 bg-indigo-500 rounded-xl uppercase text-white font-bold' onClick={(e)=>{e.preventDefault();handleConfirm();}}>Confirm</button>
              </div>

              
            </div>
          </div>

        </div>
        
        <Footer/>
    </div>
  )
}
