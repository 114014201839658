import React from 'react'
import { Link } from 'react-router-dom';
import { FaUserFriends, FaComments, FaLock } from 'react-icons/fa'; // Import icons
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';


import viratImage from '../../images/virat.jpg';


export default function Heroreg() {
    
  return (
    <>
      <div className="flex flex-col md:flex-row items-center justify-between gap-3 py-12 md:py-24 p-8 bg-gray-100">
        {/* Left Section */}
        <div className="md:w-1/2 p-4">
          <h1 className="heading text-4xl font-bold text-gray-800">Find Your Perfect Match</h1>
          <p className="mt-4 text-gray-600">
            Join our community and connect with like-minded individuals. Discover relationships that last a lifetime.
          </p>
          <button className="mt-6 px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition duration-300">
            <Link to='/signup'>Sign Up Now</Link>
          </button>
        </div>

        {/* Right Section - Carousel */}
        <div className="md:w-1/2 mt-8 md:mt-0">
          <Carousel showArrows={true} infiniteLoop={true} autoPlay={true} interval={3000} showStatus={false} showThumbs={false}>
            <div className="h-96 overflow-hidden">
              <img src={viratImage} alt="Couple 1" className="w-full h-full object-cover" />
            </div>
            <div className="h-96 overflow-hidden">
              <img src={require('../../images/image.jpg')} alt="Couple 2" className="w-full h-full object-cover" />
            </div>
            <div className="h-96 overflow-hidden">
              <img src={require('../../images/image1.png')} alt="Couple 3" className="w-full h-full object-cover" />
            </div>
            <div className="h-96 overflow-hidden">
              <img src={viratImage} alt="Couple 4" className="w-full h-full object-cover" />
            </div>
          </Carousel>
        </div>
      </div>

      <div className="px-14 py-12 md:py-24 bg-white">
        <h2 className="heading text-3xl font-bold text-center mb-8">Why Choose Us?</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* First Card */}
          <div className="flex flex-col items-center bg-gray-100 p-6 rounded-lg shadow-lg">
            <FaUserFriends className="text-blue-600 text-4xl mb-4" />
            <h3 className="heading text-xl font-semibold">Personalized Matches</h3>
            <p className="text-center text-gray-600 mt-2">
              We provide tailored matches based on your preferences and values.
            </p>
          </div>
          
          {/* Second Card */}
          <div className="flex flex-col items-center bg-gray-100 p-6 rounded-lg shadow-lg">
            <FaComments className="text-blue-600 text-4xl mb-4" />
            <h3 className="heading text-xl font-semibold">Community Support</h3>
            <p className="text-center text-gray-600 mt-2">
              Engage with our supportive community for advice and guidance.
            </p>
          </div>

          {/* Third Card */}
          <div className="flex flex-col items-center bg-gray-100 p-6 rounded-lg shadow-lg">
            <FaLock className="text-blue-600 text-4xl mb-4" />
            <h3 className="heading text-xl font-semibold">Secure Connections</h3>
            <p className="text-center text-gray-600 mt-2">
              Your privacy is our priority. We ensure a secure platform for all users.
            </p>
          </div>
        </div>
      </div>
    </>
    
  )
}
