import React from 'react'


export default function Footer() {
  return (
    <>
        <div className='bg-green-200 p-10'>
            <div className='md:flex  justify-between  '>
                <div className="flex flex-col gap-4 p-4 md:p-0">
                    <h1 className='heading text-xl font-bold '>Get In Touch</h1>
                    <p>Address: 3812 Lena Lane City Jackson Mississippi</p>
                    <p>Phone: +92 (8800) 68 - 8960</p>
                    <p>Email: info@example.com</p>
                </div>
                <div className="flex flex-col gap-4 p-4 md:p-0">
                    <h1 className='heading text-xl font-bold '>Help & Support</h1>
                    <p>About Company</p>
                    <p>Contact Us</p>
                </div>
                <div className="flex flex-col gap-4 p-4 md:p-0">
                    <h1 className='heading text-xl font-bold '>Social Media</h1>
                    <div className="flex gap-4">
                        <img src={require('../../images/instagram.png')} alt="" width={25}/>
                        <img src={require('../../images/facebook.png')} alt="" width={25}/>
                        <img src={require('../../images/youtube.png')} alt="" width={25}/>
                        <img src={require('../../images/linkedin.png')} alt="" width={25}/>
                        <img src={require('../../images/twitter.png')} alt="" width={25}/>
                    </div>
                </div>
            </div>
        
        </div>
        <p className='flex justify-center text-sm items-end'>Copyright © 2024 Sanmarry All rights reserved.</p>
    </>
    
    
  )
}
