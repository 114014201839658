import React,{lazy, Suspense,  useEffect,  useState} from 'react'
import CommonNav from './CommonNav'
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import Footer from './Footer';



const LazyPackages = lazy(()=>import('./Packages'))
export default function Signup() {



    const religiondata = {
        hindu: ['Nambudiri', 'Nair', 'Kshatriya','Ezhava','Vishwaksrma','Vellala','Araya','Pulaya','Paraya','Kuruva','Cheruman'],
        christians: ['Syrian', 'Latin', 'protestans','Evangelical','Lutheran'],
        muslims: ['Mappila', 'Sayyid', 'Sheikh','Moplah','Thangal'],
    };
    
    
    // State for selected category and item
    const [relegion,setRelegion]=useState("")
    const [cast,setCast]=useState("")

    
    // Handler for category change
    const handleCategoryChange = (e) => {
        setRelegion(e.target.value);
        setCast(''); // Reset item when category changes
        console.log(options)
    };

    // Options for the second select based on selected category
    const options = relegion in religiondata ? religiondata[relegion] : [];
    
    const [plan,setPlan] = useState('');

    
      
    //New 
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        gender: '',
        height: '',
        designation: '',
        place: '',
        district: '',
        mlevel: '',
        mnumber: '',
        relegion: '',
        cast: '',
        fname: '',
        fjob: '',
        mname: '',
        mjob: '',
        sname: '',
        smarried: '',
        age: '',
        img1: null,
        img2: null,
        img3: null,
        plan: '',
    });
        
    const handlePlan = (data)=>{
        setPlan(data);
        setFormData({
            ...formData,
            ['plan']: data, // Handle text, select, and radio inputs
        });
        
    }
    console.log(plan)

    const handleChange = (e) => {
        const { name, value, type, files } = e.target;

        if (type === 'file') {
            setFormData({
                ...formData,
                [name]: files ? files[0] : null, // Handle file uploads
            });
        } else {
            setFormData({
                ...formData,
                [name]: value, // Handle text, select, and radio inputs
            });
        }
        console.log("formData",formData)
    };
    
    
    const navigate=useNavigate();

    //Error
    const [errors, setErrors] = useState({});

    const validate = () => {
        const newErrors = {};
        if (!formData.first_name) newErrors.first_name = '*First name is required.';
        if (!formData.last_name) newErrors.last_name = '*Last name is required.';
        if (!formData.email) {
            newErrors.email = '*Email is required.';
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = '*Email is invalid.';
        }
        if (!formData.gender) newErrors.gender = '*Gender is required.';
        if (!formData.height) newErrors.height = '*Height is required.';
        if (!formData.designation) newErrors.designation = '*Designation is required.';
        if (!formData.place) newErrors.place = '*Place is required.';
        if (!formData.district) newErrors.district = '*District is required.';
        if (!formData.mlevel) newErrors.mlevel = '*Marriage level is required.';
        if (!formData.mnumber) {
            newErrors.mnumber = '*Mobile number is required.';
        } else if (!/^\d{10}$/.test(formData.mnumber)) {
            newErrors.mnumber = '*Mobile number must be exactly 10 digits.';
        }
        if (!formData.relegion) newErrors.relegion = '*Religion is required.';
        if (!formData.cast) newErrors.cast = '*Caste is required.';
        if (!formData.fname) newErrors.fname = "*Father's name is required.";
        if (!formData.fjob) newErrors.fjob = "*Father's job is required.";
        if (!formData.mname) newErrors.mname = "*Mother's name is required.";
        if (!formData.mjob) newErrors.mjob = "*Mother's job is required.";
        if (!formData.sname) newErrors.sname = "*Sibling's name is required.";
        if (!formData.smarried) newErrors.smarried = "*Sibling's marital status is required.";
        if (!formData.age) newErrors.age = '*Age is required.';
        if (!formData.img1) newErrors.img1 = '*Image 1 is required.';
        if (!formData.img2) newErrors.img2 = '*Image 2 is required.';
        if (!formData.img3) newErrors.img3 = '*Image 3 is required.';
        if (!plan) newErrors.plan = '*Plan selection is required.';
        console.log("error arry: ",newErrors)
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
   
    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("formData",formData)
        if (validate()) {
            const data = new FormData();
            Object.keys(formData).forEach((key) => {
                data.append(key, formData[key]);
            });
            
            console.log("data",data)

            try {
                const response = await axios.post(
                    `create`,
                    data,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    }
                );

                console.log(response);
                if (response.status === 200) {
                    navigate('/pay');
                } else {
                    alert('Error occurred while submitting the form.');
                }
            } catch (error) {
                console.error('Error during request:', error);
                if (error.response) {
                    alert('Response error: ' + (error.response.data.message || 'Unknown error'));
                } else if (error.request) {
                    alert('Request error: ' + (error.message || 'No response received'));
                } else {
                    alert('Request setup error: ' + error.message);
                }
            }
        }
    };
    return (
        <div>
            <CommonNav/>
            <div className="flex min-h-full flex-col justify-center px-6 py-6 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                    <h2 className="heading mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900 uppercase">Sign Up </h2>
                </div>

                <div className="mt-10 md:w-4/5 sm:mx-auto ">
                    <form  className="space-y-6" action="#" method="POST">
                        <div className='md:flex gap-10'>
                            <div className="w-full md:w-3/4 ">
                                <label htmlFor="first_name" className="block text-sm font-medium leading-6 text-left text-gray-900">First Name</label>
                                <div className="mt-2">
                                    <input id="first_name" name="first_name" type="text" value={formData.first_name} onChange={handleChange}  required className="p-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                                </div>
                                {errors.first_name && <span className='flex text-red-500 justify-start '>{errors.first_name}</span>}
                                
                                
                            </div>
                            <div className="md:w-3/4">
                                <label htmlFor="last_name" className="block text-sm font-medium leading-6 text-left text-gray-900">Last Name</label>
                                <div className="mt-2">
                                    <input id="last_name" name="last_name" type="text" onChange={handleChange}  required className="p-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                                </div>
                                {errors.last_name && <span className='flex text-red-500 justify-start '>{errors.last_name}</span>}
                            </div>
                                
                        </div>

                        <div className='md:flex gap-10'>
                            <div className="md:w-3/4">
                                <label htmlFor="gender" className="block text-sm font-medium leading-6 text-left text-gray-900">Gender</label>
                                <div className="mt-2 flex  gap-10">
                                    <div className="flex gap-4">
                                    <input id="male" name="gender" value="male" type="radio" onChange={handleChange}  required className="block w-full  sm:text-sm sm:leading-6"/>
                                    <label htmlFor="male">Male</label>
                                    </div>
                                    <div className="flex gap-4">
                                    <input id="female" name="gender" value="female" type="radio" onChange={handleChange}  required className="block w-full  sm:text-sm sm:leading-6"/>
                                    <label htmlFor="female">Female</label>
                                    </div>
                                        
                                </div>
                                {errors.gender && <span className='flex text-red-500 justify-start '>{errors.gender}</span>}
                            </div>
                            <div className="md:w-3/4">
                                <label htmlFor="height" className="block text-sm font-medium leading-6 text-left text-gray-900">Height</label>
                                <div className="mt-2">
                                    <input id="height" name="height" type="text" onChange={handleChange} required className="p-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                                </div>
                                {errors.height && <span className='flex text-red-500 justify-start '>{errors.height}</span>}
                            </div>
                        </div>

                        <div className='md:flex gap-10'>
                            <div className="md:w-3/4">
                                <label htmlFor="designation" className="block text-sm font-medium leading-6 text-left text-gray-900">Designation</label>
                                <div className="mt-2">
                                    <input id="designation" name="designation" type="text" onChange={handleChange}  required className="p-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                                </div>
                                {errors.designation && <span className='flex text-red-500 justify-start '>{errors.designation}</span>}
                            </div>
                            <div className="md:w-3/4">
                                <label htmlFor="place" className="block text-sm font-medium leading-6 text-left text-gray-900">Place</label>
                                <div className="mt-2">
                                    <input id="place" name="place" type="text" onChange={handleChange} required className="p-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                                </div>
                                {errors.place && <span className='flex text-red-500 justify-start '>{errors.place}</span>}
                            </div>
                        </div>

                        <div className='md:flex gap-10'>
                            <div className="md:w-3/4">
                                <label htmlFor="district" className="block text-sm font-medium leading-6 text-left text-gray-900">District</label>
                                <div className="mt-2">
                                    <input id="district" name="district" type="text" onChange={handleChange} required className="p-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300  focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                                </div>
                                {errors.district && <span className='flex text-red-500 justify-start '>{errors.district}</span>}
                            </div>
                            <div className="md:w-3/4">
                                <label htmlFor="mlevel" className="block text-sm font-medium leading-6 text-left text-gray-900">Marriage level</label>
                                <div className="mt-2  ">
                                    <select className='flex w-full p-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6' name="mlevel" id="mlevel" onChange={handleChange}>
                                        <option className='ml-auto' value="">Select a category</option>
                                        <option className='ml-auto' value="newmarriage">Newly Married</option>
                                        <option className='ml-auto' value="remarriage">Remarriage</option>
                                    </select>
                                </div>
                                {errors.mlevel && <span className='flex text-red-500 justify-start '>{errors.mlevel}</span>}
                            </div>
                        </div>

                        <div className='md:flex gap-10'>
                            <div className="md:w-3/4">
                                <label htmlFor="email" className="block text-sm font-medium leading-6 text-left text-gray-900">Email</label>
                                <div className="mt-2">
                                    <input id="email" name="email" type="email" onChange={handleChange} autoComplete='email' required className="p-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                                </div>
                                {errors.email && <span className='flex text-red-500 justify-start '>{errors.email}</span>}
                            </div>
                            <div className="md:w-3/4">
                                <label htmlFor="mnumber" className="block text-sm font-medium leading-6 text-left text-gray-900">Mobile Number</label>
                                <div className="mt-2">
                                    <input id="mnumber" name="mnumber" type="text" onChange={handleChange} required className="p-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                                </div>
                                {errors.mnumber && <span className='flex text-red-500 justify-start '>{errors.mnumber}</span>}
                            </div>
                        </div>

                        <div className="md:flex gap-10">
                            <div className="md:w-3/4">
                                <label htmlFor="relegion" className="block text-sm font-medium leading-6 text-left text-gray-900">Relegion</label>
                                
                                <select name="relegion" value={formData.relegion} onChange={(event) => { handleCategoryChange(event); handleChange(event);}} className='p-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ing-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'>
                                    <option value="">Select a category</option>
                                    <option value="hindu">Hindu</option>
                                    <option value="christians">Christians</option>
                                    <option value="muslims">Muslims</option>
                                </select>
                                {errors.relegion && <span className='flex text-red-500 justify-start '>{errors.relegion}</span>}
                            </div>
                            <div className="md:w-3/4">
                                <label htmlFor="cast" className="block text-sm font-medium leading-6 text-left text-gray-900">Cast</label>
                                
                                <select value={formData.cast} onChange={handleChange} name='cast' disabled={!relegion}  className='p-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ing-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'>
                                    <option value="">Select an item</option>
                                    {options.map((item) => (
                                        <option key={item} value={item}>
                                        {item}
                                        </option>
                                    ))}
                                </select>
                                {errors.cast && <span className='flex text-red-500 justify-start '>{errors.cast}</span>}
                            </div>
                        </div>


                        <div className="md:flex gap-10">
                            <div className="md:w-3/4">
                            <label htmlFor="fname" className="block text-sm font-medium leading-6 text-left text-gray-900">Father Name</label>
                                <div className="mt-2">
                                    <input id="fname" name="fname" type="text" onChange={handleChange} required className="p-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                                </div>
                                {errors.fname && <span className='flex text-red-500 justify-start '>{errors.fname}</span>}
                            </div>
                            <div className="md:w-3/4">
                                <label htmlFor="fjob" className="block text-sm font-medium leading-6 text-left text-gray-900">Job</label>
                                <div className="mt-2">
                                    <input id="fjob" name="fjob" type="text" onChange={handleChange}  required className="p-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                                </div>
                                {errors.fjob && <span className='flex text-red-500 justify-start '>{errors.fjob}</span>}
                            </div>
                        </div>
                        
                        <div className="md:flex gap-10">
                            <div className=" md:w-3/4">
                            <label htmlFor="mname" className="block text-sm font-medium leading-6 text-left text-gray-900">Mother Name</label>
                                <div className="mt-2">
                                    <input id="mname" name="mname" type="text" onChange={handleChange} required className="p-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                                </div>
                                {errors.mname && <span className='flex text-red-500 justify-start '>{errors.mname}</span>}
                            </div>
                            <div className="md:w-3/4">
                                <label htmlFor="mjob" className="block text-sm font-medium leading-6 text-left text-gray-900">Job</label>
                                <div className="mt-2">
                                    <input id="mjob" name="mjob" type="text" onChange={handleChange} required className="p-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                                </div>
                                {errors.mjob && <span className='flex text-red-500 justify-start '>{errors.mjob}</span>}
                            </div>
                        </div>

                        <div className="md:flex gap-10">
                            <div className="md:w-3/4">
                                <label htmlFor="sname" className="block text-sm font-medium leading-6 text-left text-gray-900">Sibling Name</label>
                                <div className="mt-2">
                                    <input id="sname" name="sname" type="text" onChange={handleChange} required className="p-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                                </div>
                                {errors.sname && <span className='flex text-red-500 justify-start '>{errors.sname}</span>}
                            </div>
                            <div className="flex justify-start flex-col md:w-3/4">
                                <label htmlFor="smarried" className="block text-sm font-medium leading-6 text-left text-gray-900">Married</label>
                                <div className="flex gap-10">
                                    <div className="mt-2 flex gap-4">
                                    <input id="smarriedy" name="smarried" type="radio" value="yes" onChange={handleChange} required className="block w-full  sm:text-sm sm:leading-6"/>
                                    <label htmlFor="smarriedy">Yes</label>
                                    </div>
                                    <div className="mt-2 flex gap-4">
                                        
                                        <input id="smarriedn" name="smarried" type="radio" value="no" onChange={handleChange} required className="block w-full  sm:text-sm sm:leading-6"/>
                                        <label htmlFor="smarriedn">No</label>
                                    
                                    </div>
                                </div>
                                {errors.smarried && <span className='flex text-red-500 justify-start '>{errors.smarried}</span>}
                                
                            </div>
                        </div>
                        <div className="mt-6 md:flex gap-10">
                            <div className="md:w-3/4">
                                <label htmlFor="mnumber" className="block text-sm font-medium leading-6 text-left text-gray-900">Age</label>
                                <div className="mt-2">
                                    <input id="age" name="age" type="text" onChange={handleChange} required className="p-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                                </div>
                                {errors.age && <span className='flex text-red-500 justify-start '>{errors.age}</span>}
                            </div>
                            <div className="md:w-3/4">
                                <label htmlFor="sname" className="block text-sm font-medium leading-6 text-left text-gray-900">Image 1</label>
                                <div className="mt-2">
                                <input id="img1" name="img1" type="file" onChange={handleChange} required className=" sm:text-sm sm:leading-6"/>
                                </div>
                                {errors.img1 && <span className='flex text-red-500 justify-start '>{errors.img1}</span>}
                            </div>
                            
                            
                        </div>
                        <div className="md:flex gap-10">
                        
                            
                            <div className="md:w-3/4">
                                <label htmlFor="sname" className="block text-sm font-medium leading-6 text-left text-gray-900">Image 2</label>
                                <div className="mt-2">
                                <input id="img2" name="img2" type="file" onChange={handleChange}  required className="block w-full  sm:text-sm sm:leading-6"/>
                                </div>
                                {errors.img2 && <span className='flex text-red-500 justify-start '>{errors.img2}</span>}
                            </div>
                            <div className="md:w-3/4">
                                <label htmlFor="sname" className="block text-sm font-medium leading-6 text-left text-gray-900">Image 3</label>
                                <div className="mt-2">
                                <input id="img3" name="img3" type="file" onChange={handleChange}  required className="block w-full  sm:text-sm sm:leading-6"/>
                                </div>
                                {errors.img3 && <span className='flex text-red-500 justify-start '>{errors.img3}</span>}
                            </div>
                            
                        </div>

                        
                        
                        
                    </form>

                    
                </div>
                <div className="p-2 md:pl-12 md:pr-12">
                <Suspense fallback={<div>Loading Packages....</div>}>
                    <LazyPackages onPlan={handlePlan} />
                </Suspense>
                {errors.plan && <span className='flex text-red-500 justify-center '>{errors.plan}</span>}
                </div>
                
                <div className='flex justify-center pt-2'>
                    <button type="submit" onClick={handleSubmit} className="p-5 uppercase font-bold rounded-md bg-indigo-600 px-3 py-1.5 text-sm leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Sign Up</button>
                </div>
                <p className="mt-6 text-center text-sm text-gray-500">
                    Already a member?
                <a href="./login" className="font-semibold pl-2 leading-6 text-indigo-600 hover:text-indigo-500">Signin</a>
                </p>
            </div>
            <Footer/>
        </div> 
    )
}
