import React, { useState,useEffect } from 'react'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import UserNav from './UserNav'
import Visitors from './Visitors';
import Footer from '../common/Footer';

export default function Profile() {

    const token=localStorage.getItem('token');
    console.log("token",token)
    const [profile,setprofile] = useState()
    

    const navigate = useNavigate()
    
    useEffect(()=>{
        async function fetchData(){
            try{
                const response=await axios.get(`profile`,{
                    headers:{
                      'Content-Type':'application/json',
                      'Authorization':`Token ${token}`,
                    }
                })
                if(response.status===200){
                    if (Object.keys(response.data).length > 0) {
                      console.log(response.data.data);
                      setprofile(response.data.data);
                      console.log("profile",profile);
                      
                    } else {
                        alert('Data is empty')
                        console.warn('Data is empty');
                    }
                    
                }
                else{
                    alert("error")
                }
            }
            catch(error){
                console.error(error)
            }
        }
        fetchData();
    },[token])


    function handleUpdate(id){
      navigate(`/update/${id}`)
    }

    function handleCpass(){
      navigate(`/changepass`)
    }

    function printOut(){
        const printDiv = document.getElementById('print-div').innerHTML;
        const printWindow = window.open(",",'fullscreen=yes');
        printWindow.document.write(printDiv);
        printWindow.print();
        printWindow.close();

    }
    
    {if (!profile) 
      return <div>Loading...</div>; // Show loading until data is fetched
    }
    return (
      <div className='bg-white'>
        <UserNav/>
        <div className="md:flex w-full mb-6">
            <div className=" flex justify-center flex-col md:w-1/3 p-6">
                {profile.img1 && <img src={profile.img1} alt="img1" />}
            </div>
            <div className=" flex flex-col  md:w-2/3 p-6 gap-4 md:overflow-auto md:max-h-[80vh]">
                
                <h1 className='heading text-5xl capitalize'>{profile.user.first_name} {profile.user.last_name}</h1>
                <div className="flex gap-2">
                    <span className='bg-yellow-300 p-1'>{profile.designation}</span>
                    <span className='bg-yellow-300 p-1'>{profile.place}</span>
                </div>

                <h1 className='heading text-xl uppercase font-semibold'>Personal Information</h1>
                <div className="md:flex gap-4 w-full pt-3">
                    <div className="mb-4 flex flex-col justify-between md:w-1/2 gap-4">
                        <p className='flex items-center gap-2'>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                        </svg>

                            Name: {profile.user.first_name} {profile.user.last_name}</p>
                        <p className='flex items-center gap-2'>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                        </svg>Age: {profile.age}</p>
                        <p className='flex items-center gap-2'>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                        </svg>Gender: {profile.gender}</p>
                        <p className='flex items-center gap-2'>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                        </svg>Height: {profile.height} Cm</p>
                        
                        <p className='flex items-center gap-2'>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                        </svg>Religion: {profile.relegion}</p>
                        <p className='flex items-center gap-2'>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                        </svg>Caste: {profile.cast}</p>
                        <p className='flex items-center gap-2'>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                        </svg>Profession: {profile.designation}</p>
                    </div>
                    <div className="mb-4 flex flex-col md:w-1/2 justify-between gap-4">
                        <p className='flex items-center gap-2'>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                        </svg>Place: {profile.place}</p>
                        <p className='flex items-center gap-2'>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                        </svg>Father Name: {profile.fname}</p>
                        <p className='flex items-center gap-2'>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                        </svg>Father Job: {profile.fjob}</p>
                        <p className='flex items-center gap-2'>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                        </svg>Mother Name: {profile.mname}</p>
                        <p className='flex items-center gap-2'>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                        </svg>Mother Job: {profile.mjob}</p>
                        <p className='flex items-center gap-2'>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                        </svg>Sibling Name: {profile.sname}</p>
                        <p className='flex items-center gap-2'>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                        </svg>Sibling Married: {profile.smarried}</p>
                 
                    </div>
                    
                </div>
                <hr />

                <h1 className='heading text-xl uppercase font-semibold'>Photo gallery</h1>
                <div className="flex flex-wrap gap-6 items-center justify-center">
                    {profile.img2 && (
                        <img className='object-contain h-48 w-48 md:w-60' src={profile.img2} alt="img2" />
                    )}
                    {profile.img1 && (
                        <img className='object-contain h-48 w-48 md:w-60' src={profile.img1} alt="img1" />
                    )}
                    {profile.img3 && (
                        <img className='object-contain h-48 w-48 md:w-60' src={profile.img3} alt="img3" />
                    )}
                </div>
                <hr />

                <h1 className='heading text-xl uppercase font-semibold'>Contact Info</h1>
                <div className="flex flex-col gap-4 pt-3">
                    <p>Phone: {profile.mnumber}</p>
                    <p>Email: {profile.user.email}</p>
                    <p>Place: {profile.district}</p>
                </div>
                <hr />

                

                <Visitors/>
                <div className="flex flex-col sm:flex-row justify-center gap-4 p-4">
                    <button onClick={(e) => { e.preventDefault(); handleUpdate(profile.id); }} className='p-3 bg-indigo-500 rounded-xl uppercase text-white font-bold flex-1'>
                        Update
                    </button>
                    <button onClick={(e) => { e.preventDefault(); printOut(); }} className='p-3 bg-indigo-500 rounded-xl uppercase text-white font-bold flex-1'>
                        Print
                    </button>
                    <button onClick={(e) => { e.preventDefault(); handleCpass(); }} className='p-3 bg-indigo-500 rounded-xl uppercase text-white font-bold flex-1'>
                        Change Password
                    </button>
                </div>
                
            </div>

        </div>

        <div id='print-div' className='hidden' style={{ padding: '20px', textAlign: 'center' }}>
            {/* Top Center Image */}
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <img src={profile.img1} alt="Profile"  style={{ width: '150px' }} />
            </div>
            
            <h1>Sandeep Shine</h1>
            <div style={{ display: 'flex', justifyContent: 'space-between'  }}>
                <div style={{ display: 'flex',flexDirection: 'column', justifyContent: 'center',textTransform:'capitalize' }}>
                    <p>Age: {profile.age}</p>
                    <p>Height: {profile.height} cm</p>
                    <p>Phone: {profile.mnumber}</p>
                    <p style={{textTransform: 'lowercase'}}>Email: {profile.user.email}</p>
                    <p>Profession: {profile.designation}</p>
                    <p>Religion: {profile.relegion}</p>
                    <p>Caste: {profile.cast}</p>
                </div>
                <div style={{ display: 'flex',flexDirection: 'column', justifyContent: 'center',textTransform:'capitalize' }}>
                    <p>Place: {profile.place}</p>
                    <p>Father Name: {profile.fname}</p>
                    <p>Father Job: {profile.fjob}</p>
                    <p>Mother Name: {profile.mname}</p>
                    <p>Mother Job: {profile.mjob}</p>
                    <p>Sibling Name: {profile.sname}</p>
                    <p>Sibling Married: {profile.smarried}</p>
                </div>
            </div>
            
            {/* Three Images at the Bottom */}
            <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'center', gap: '10px' }}>
                <img src={profile.img1} alt="Image 1" style={{ width: '100px', height: '100px' }} />
                <img src={profile.img2} alt="Image 2" style={{ width: '100px', height: '100px' }} />
                <img src={profile.img3} alt="Image 3" style={{ width: '100px', height: '100px' }} />
            </div>

        
        
        </div>
        
        <Footer/>
        
      </div>
         
    )
}
