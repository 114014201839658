import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import UserNav from './UserNav';
import Footer from '../common/Footer';

export default function Changepass() {
    const token=localStorage.getItem('token');
    console.log("token",token)
    const [username,setUsername]=useState("")
    const [password,setPassword]=useState("")
    const [cpassword,setCpassword]=useState("")
    const [errors, setErrors] = useState({});

    const validate = () => {
        const newErrors = {};
        if (!username) newErrors.username = '*Member ID is required.';
        if (!password) newErrors.password = '*Password is required.';
        if (!cpassword) {
          newErrors.cpassword = '*Confirm Password is required.';
        } else {
          if (password === cpassword) {
              newErrors.cpassword = '*Passwords can not be equal.';
          }
          else if (cpassword.length < 8) {
            newErrors.cpassword = '*Password must be at least 8 characters long.';
          }
          else if (!/\d/.test(cpassword)) {
              newErrors.cpassword = (newErrors.cpassword || '') + ' *Confirm Password must contain at least one digit.';
          }
          else if (!/[A-Z]/.test(cpassword)) {
              newErrors.cpassword = (newErrors.cpassword || '') + ' *Confirm Password must contain at least one uppercase letter.';
          }
          else if (!/[a-z]/.test(cpassword)) {
              newErrors.cpassword = (newErrors.cpassword || '') + ' *Confirm Password must contain at least one lowercase letter.';
          }
          else if (!/[!@#$%^&*(),.?":{}|<>]/.test(cpassword)) {
              newErrors.cpassword = (newErrors.cpassword || '') + ' *Confirm Password must contain at least one special character.';
          }
      }
        setErrors(newErrors);
        console.log(newErrors)
        return Object.keys(newErrors).length === 0;
    };
    const navigate=useNavigate();

    
  
    async function handleSubmit(){
        if (validate()) {

            const data={
                username:username,
                password:password,
                cpassword:cpassword
            }
            try{
                  const response=await axios.post(`cngpass`,data,{
                    headers:{
                      'Content-Type':'application/json',
                      'Authorization':`Token ${token}`,
                    }
                  })
                  
                  console.log(response)
                  if(response.status===200){
                    alert("password changed")
                    navigate('/profile');
                  }
                  else{
                    alert("error in changing password")
                  }
            }
            catch(error){
                console.error("Login failed:", error.response.data);
                // Optionally show an error message to the user
                alert((error.response.data.error || 'Invalid credentials'));
            }
        }
    }
  return (
    <div>
      <UserNav/>
      <div className="flex min-h-full flex-col justify-center px-6 m-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <h2 className="heading mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">Change Password</h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <form className="space-y-6" action="#" method="POST">
                <div>
                    <label htmlFor="username" className="block text-sm font-medium leading-6 text-left text-gray-900">MemberID</label>

                    <div className="mt-2">
                        <input id="username" name="username" type="text" onChange={(e)=>setUsername(e.target.value)} required className="p-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                    </div>
                    {errors.username && <span className='flex text-red-500 justify-start '>{errors.username}</span>}
                </div>

                <div>
                    <label htmlFor="password" className="block text-sm font-medium leading-6 text-left text-gray-900">Password</label>
                    <div className="mt-2">
                        <input id="password" name="password" type="password" onChange={(e)=>setPassword(e.target.value)} required className="p-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                    </div>
                    {errors.password && <span className='flex text-red-500 justify-start '>{errors.password}</span>}
                </div>
                
                <div>
                    <label htmlFor="cpassword" className="block text-sm font-medium leading-6 text-left text-gray-900">Confirm Password</label>
                    <div className="mt-2">
                        <input id="cpassword" name="cpassword" type="password" onChange={(e)=>setCpassword(e.target.value)} required className="p-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                    </div>
                    {errors.cpassword && <span className='flex text-red-500 justify-start '>{errors.cpassword}</span>}
                </div>

                <div>
                    <button type="submit" onClick={(e)=>{e.preventDefault();handleSubmit()}} className="flex w-full justify-center rounded-md bg-custombutton px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-custombutton1 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Change Password</button>
                </div>
            </form>

            
        </div>
    </div>
      <Footer/>
    </div>
  )
}
