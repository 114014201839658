import React, {useState} from 'react'
import { useNavigate,Link  } from 'react-router-dom';


export default function AdminNav() {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };
  const navigate=useNavigate();
  async function logout(){
    localStorage.removeItem('token')
    navigate('/');
  }
  return (
    <div className='bg-indigo-500'>
      <div className='flex justify-between md:pr-10 pt-6 pb-6 pl-4 items-center'>
        <div className="text-2xl uppercase font-bold text-white">SanMarry</div>
        <div className="hidden md:block">
            <ul className='flex gap-5 text-xl'>
            <li><a className='hover:cursor-pointer font-bold hover:text-white' onClick={(e)=>{e.preventDefault();logout();}}>Logout</a></li>
            </ul>
        </div>
        <div className="md:hidden pr-4">
          <button onClick={toggleMobileMenu} className="text-white">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6">
              <path fillRule="evenodd" d="M3 6.75A.75.75 0 0 1 3.75 6h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 6.75ZM3 12a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 12Zm0 5.25a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75a.75.75 0 0 1-.75-.75Z" clipRule="evenodd" />
            </svg>
          </button>
        </div>
      </div>
      {isMobileMenuOpen && (
      <div className="md:hidden">
        <ul class="flex flex-col text-left pl-4 gap-2 text-xl pb-4">
          <li className='mb-6'><Link className='hover:text-white font-bold' to="/admuseres">Home</Link></li>
          <li className='mb-6'><Link className='hover:text-white font-bold' to="/packages">Packages</Link></li>
          <li className='mb-6'><Link className='hover:text-white font-bold' to="/admnots">Notifications</Link></li>
          <li><Link className='hover:cursor-pointer font-bold hover:text-white' onClick={(e)=>{e.preventDefault();logout();}}>Logout</Link></li>
          
        </ul>
      </div>
      )}
    </div>
  )
}
