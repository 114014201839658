import React from 'react'
import UserNav from './UserNav'
import Hero from '../common/Hero'
import About from '../common/About'
import Packages from '../common/Packages';
import Footer from '../common/Footer';

export default function UserHome() {

    const token=localStorage.getItem('token');
    console.log(token)
      
    return (
      <div>
        <UserNav/>
        <Hero/>
        <About/>
        <Footer/>
      </div>
    )
}
