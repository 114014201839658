import React, { useState, useEffect } from 'react'
import axios from 'axios';


export default function AdminUserPlan({ id }) {
    const token=localStorage.getItem('token');
    const [userPlan, setUserPlan] = useState(null);

    useEffect(()=>{
        async function fetchData(){
          try{
              const response=await axios.get(`usersplan/${id}`,{
                headers:{
                  'Content-Type':'application/json',
                  'Authorization':`Token ${token}`,
                }
              })
              if(response.status===200){
                console.log(response);
                setUserPlan(response.data.data);
                console.log(userPlan)
              }
              else{
                alert("error")
              }
            }
          catch(error){
            console.error(error)
          }
        }
        fetchData();
    },[token])

    const month = 2;
    const processValidity = (validity) => {
      const result = validity / 30;
      if (Number.isInteger(result)) {
        if (result===1){
          return `${result} month`;
        }
        else{

          return `${result} months`; // Add month if result is an integer
        }
      } else {
        return `${validity} days`; // Append 'days' if result is not an integer
      }
    };

    {if (!userPlan ) 
        return <div>Loading...</div>; // Show loading until data is fetched
    }
    return (
        <div>
            <h1 className='heading text-2xl font-bold text-center uppercase mt-6' >Current Plan</h1>
            <div className="flex  justify-around text-center ">
            {
                
                <div  className="pack1 flex flex-1 flex-col gap-4 shadow-2xl m-10 p-10  rounded-lg">
                <p className='heading text-2xl uppercase font-bold'>{userPlan.plan.pname}</p>
                <div className="flex m-auto text-left flex-col gap-3">
                    <div className="flex gap-2 justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6 text-green-600">
                            <path fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z" clipRule="evenodd" />
                        </svg>
                        <p className='text-xl'>{processValidity(userPlan.plan.validity)}</p>
                    </div>
                    <div className="flex gap-2">
                        <p className='text-xl'>{userPlan.sdate} </p><p>to</p>
                        <p className='text-xl'>{userPlan.edate}</p>
                    </div>
                    
                </div>
                <p className='text-xl'>{userPlan.plan.price} &#x20B9;</p>
                
                </div>
                    
            }
            </div>
        </div>
    )
}
