import React, { useState,useEffect } from 'react'
import axios from 'axios'

import AdminNav from './AdminNav'
import Sidebar from './Sidebar'
import Footer from '../common/Footer'

export default function AdminPackages() {

  const [pname,setPname] = useState("")
  const [pval,setPval] = useState("")
  const [price,setPrice] = useState("")
  const [packages,setPackages] = useState([])
  
  const token=localStorage.getItem('token');

  useEffect(()=>{
      async function fetchData(){
          try{
                const response=await axios.get(`packages`,{
                  headers:{
                    'Content-Type':'application/json',
                    'Authorization':`Token ${token}`,
                  }
                })
                if(response.status===200){
                  
                  setPackages(response.data.data);
                  console.log(response.data.data)
                  console.log("package",packages)
                }
                else{
                  alert("error")
                }
              }
              catch(error){
                console.error(error)
              }
      }
      fetchData();
  },[token,price])

  
  return (
    <div>
      <AdminNav/>
      <div class="flex">
        <Sidebar/> 
        <main className="flex-1 p-6 h-screen overflow-y-auto">
            
          <div className=" shadow-lg rounded-lg m-6">
              <h2 className="heading text-xl font-semibold text-left rounded text-white p-1 pl-4  bg-black">All Packages</h2>
              <div className="shadow-lg rounded-lg pl-6 pr-6 pb-6 overflow-x-auto bg-white">

                  <table className='table-auto w-full border-collapse '>
                      <thead className=''>
                          <tr className='font-bold uppercase '>
                              <td className='pt-4'>Name</td>
                              <td className='pt-4'>Validity</td>
                              <td className='pt-4'>Price</td>
                          </tr>
                      </thead>
                      <tbody className='pt-10'>
                        {
                          packages.map((item)=>{
                            return(
                            <tr className='pt-2'>
                              <td key={item.id}>{item.pname}</td>
                              <td key={item.id}>{item.validity}</td>
                              <td key={item.id}>{item.price}</td>
                              
                            </tr>
                            )
                          })
                        }
                          
                          
                      </tbody>
                      
                  </table>
              </div>
          </div>
            
        </main> 
        
      </div>
      <Footer/>
    </div>
  )
}
