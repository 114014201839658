import React, { useState,useEffect } from 'react'
import axios from 'axios';

import UserNav from './UserNav'
import Footer from '../common/Footer';

export default function Message() {


  const token=localStorage.getItem('token');
  console.log(token)
  const username=localStorage.getItem('username');
  console.log("username",username)
  const [profile,setprofile] = useState([])
  const [chat,setChat] = useState([])
  const [selected,setSelected] = useState()
  const [sendmes,setSendmes] = useState("")
  const [chatid,setChatid] = useState("")
  const [block,setBlock] = useState(false)
  
  const [isModalOpen, setIsModalOpen] = useState(false);
  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(()=>{
    async function fetchData(){
      try{
        const response=await axios.get(`users`,{
          headers:{
            'Content-Type':'application/json',
            'Authorization':`Token ${token}`,
          }
        })
        if(response.status===200){
          console.log(response);
          setprofile(response.data.data);
          console.log(response.data.data);
        }
        else{
          alert("error")
        }
      }
      catch(error){
        console.error(error)
      }
    }
    fetchData();
  },[])

  async function chatUser(id) {
    try{
      const response=await axios.get(`chats/${id}`,{
        headers:{
          'Content-Type':'application/json',
          'Authorization':`Token ${token}`,
        }
      })
      if(response.status===200){
        console.log(response);
        setChat(response.data.data);
        console.log("chat",chat);
      }
      else{
        alert("error")
      }
    }
    catch(error){
      console.error(error)
    }
  }

  

  async function handleSend() {
    
    const data={
      mess:sendmes,
    }
    try{
      const response=await axios.post(`sendchat/${chatid}`,data,{
        headers:{
          'Content-Type':'application/json',
          'Authorization':`Token ${token}`,
        }
      })
      
      if(response.status===200){
        
        setSendmes("")
        chatUser(chatid)
      }
      else{
        alert("error")
      }
    }
    catch(error){
      console.error(error)
      alert("try error")
    }
  }
  
  async function handleBlock() {
    console.log("chatid",chatid)
    try{
      const response=await axios.put(`blockuser/${chatid}`,{},{
        headers:{
          'Content-Type':'application/json',
          'Authorization':`Token ${token}`,
        }
      })
      
      if(response.status===200){
        
        alert("Blocked")
      }
      else{
        alert("error")
      }
    }
    catch(error){
      console.error(error)
      alert("try error")
    }
  }

  return (
    <div>
        <UserNav/>
        <div className="flex justify-center h-screen bg-gray-100">
            <div className="w-full sm:w-2/3 lg:w-1/2 h-[calc(100%-2rem)] m-4 overflow-auto border border-gray-300 rounded-lg p-4">
            <div className="grid grid-cols-1 gap-4">
                {profile.map((item) => {
                const handleSelect = (e) => {
                    e.preventDefault();
                    if (item.blockedusers.includes(username)) {
                    setBlock(true);
                    } else {
                    setBlock(false); // Optional: set to false if not blocked
                    }
                    chatUser(item.id);
                    setSelected(item);
                    setChatid(item.id);
                    setIsModalOpen(true);
                };

                return (
                    <div
                    key={item.id} // Added key prop for better rendering performance
                    className="flex flex-wrap gap-4 p-4 bg-blue-500 text-white text-center rounded-lg cursor-pointer hover:bg-blue-600 transition"
                    onClick={handleSelect}
                    >
                    <img
                        src={item.img1}
                        alt="img"
                        className="h-16 w-16 object-cover object-end rounded-full"
                    />
                    <p className="flex text-xl justify-center items-center font-bold capitalize">
                        {item.user.username}
                    </p>
                    </div>
                );
                })}
            </div>
            </div>
        </div>


        {isModalOpen && (
            <div className="fixed right-0 top-20 h-96 w-80 bg-white shadow-lg p-2 flex flex-col">
                {/* Header */}
                <div className="flex items-center justify-between mb-4">
                  <div className="flex items-center w-12 h-12">
                      <img
                          src={selected.img1}
                          alt="User" 
                          className="rounded-full mr-2"
                      />
                      <span className="font-semibold capitalize">{selected.user.username}</span>
                  </div>
                  <div className="flex items-center">
                      {block ? (
                          <span className="text-red-500 mr-2">Blocked</span>
                      ) : (
                          <button className="btn mr-2 p-1 rounded-md bg-red-400 font-semibold" onClick={(e) => { e.preventDefault(); handleBlock(); }}>Block</button>
                      )}
                      <button 
                          onClick={closeModal}
                          className="text-gray-500 hover:text-gray-700 text-xl"
                      >
                          &times;
                      </button>
                  </div>
                </div>

                {/* Messages */}
                <div className="flex-1 overflow-y-auto mb-4 p-2 bg-slate-300">
                    {chat.length === 0 ? (
                        <p className="text-gray-500 text-center">No messages</p>
                    ) : (
                        <ul>
                        {chat
                            .sort((a, b) => a.id - b.id)
                            .map((message) => (
                            <li 
                                key={message.id} 
                                className={`mb-2 text-xl ${message.receiver.username === username ? 'text-right' : 'text-left'}`}
                            >
                                {message.mess}
                            </li>
                            ))}
                        </ul>
                    )}
                </div>

                {/* Text Area and Send Button */}
                {!block && 
                    <div className="flex items-center">
                        <textarea
                            rows="1"
                            className="flex-1 border rounded-md p-2 mr-2"
                            placeholder="Type a message..."
                            onChange={(e)=>setSendmes(e.target.value)} value={sendmes}
                        ></textarea>
                        <button className="bg-blue-500 text-white rounded-md px-4 py-2" onClick={(e)=>{e.preventDefault();handleSend();}}>
                            Send
                        </button>
                    </div>
                }
            </div>
            )}
      <Footer/>
    </div>

  )
}
